.selection-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.datepicker-wrapper {
  z-index: 10;
  left: 0;
  top: 40px;
  max-width: fit-content;
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0 1px 6px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 10%);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.selection-right {
  position: relative;
}
.visual-box-wrapper {
  padding: 5px 5px;
}
.fields-wrapper,
.fields-wrapper-group {
  position: relative;
}
form .fields-wrapper-group > div {
  display: flex;
}
form .fields-wrapper-group > div button {
  border: none;
  background: #151b1e;
  color: #fff;
  border-radius: 0px 5px 5px 0px;
}
.fields-wrapper label,
.fields-wrapper-group label {
  text-transform: capitalize;
  margin-bottom: 0px;
  font-size: 11px;
  position: absolute;
  padding: 0px 5px;
  left: 5px;
  top: -8px;
  background: #fff;
  /* pointer-events: none; */
  z-index: 1;
}
.create-report-modal-inner .fields-wrapper label,
.create-report-modal-inner .fields-wrapper-group label,
.add-sheet-link-modal .fields-wrapper label,
.add-sheet-link-modal .fields-wrapper-group label {
  position: relative;
  top: 0;
  left: 0;
  background-color: transparent;
  margin-bottom: 8px;
  color: #ebebeb;
}
/* :is(.create-report-modal-inner) .fields-wrapper label, .fields-wrapper-group label {
  position: relative;
  top: 0;
  left: 0;
  background-color: transparent;
  margin-bottom: 8px;
  color: #ebebeb;
} */

/* .add-sheet-link-modal .fields-wrapper label , .add-sheet-link-modal .fields-wrapper-group label {
  position: relative;
  top: 0;
  left: 0;
  background-color: transparent;
  margin-bottom: 8px;
  color: #ebebeb;
} */
/* .add-sheet-link-modal .fields-wrapper > button {
  width: 100%;
  background-color: #003ed4;
  border-radius: 5px;
  text-transform: uppercase;
} */

.report-type-switcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}
.report-type-switcher button {
  background-color: #f1f1f1;
  border: 1px solid #000;
  border-radius: 0px;
  text-transform: uppercase;
  padding: 5px 25px;
}
.report-type-switcher button.active {
  background-color: #000;
  color: #fff;
}
form .fields-wrapper > button {
  background-color: #000;
  color: #fff;
  border: none;
  font-size: 14px;
  border-radius: 5px;
  min-width: 180px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}
form .fields-wrapper > button.btn-sm {
  font-size: inherit;
  height: inherit;
  min-width: inherit;
  margin: inherit;
}
.visual-box-inner {
  /* margin-top: 0px; */
}
.bayesian-report {
  padding: 10px 0px;
}
.bayesian-report p {
  margin-bottom: 0px;
}
.segment-wrapper {
  /* background: #fbfbfba3;
  padding: 15px 10px;
  border-radius: 5px; */
  margin-top: 12px;
  /* border: 1px solid #2078f9; */
}
.visual-box-outer {
  position: relative;
  margin: 25px 0px 25px;
}
/* .portfolio-creater{
  padding: 20px 10px;
  margin-bottom: 20px;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
} */
.secondary-dimens-wrapper {
  margin-top: 15px;
}
.secondary-dimens-wrapper .secondary-dimens-inner {
  display: flex;
  align-items: center;
}
.secondary-dimens-wrapper .secondary-dimens-inner .dimens-select {
  max-width: 33%;
  width: 100%;
  margin-right: 0.25%;
}
.visual-box-outer > h2 {
  border-radius: 2px;
  position: absolute;
  font-size: 12px;
  background: #f1f1f1;
  padding: 4px 15px;
  top: -16px;
  left: 10px;
}
.visual-box-inner .visual-box {
  display: flex;
  align-items: normal;
  justify-content: space-between;
  margin-bottom: 5px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
}
.strategy-form-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-strategy-cta {
  border: 1px solid #f1f1f1;
  padding: 2px 15px;
  border-radius: 3px;
}
.strategy-form-inner .strategy-name-field {
  max-width: 80%;
  width: 100%;
}
.strategy-form-inner .strategy-prepared-delete {
  display: flex;
  gap: 15px;
  align-items: center;
}
.strategy-form-inner .strategy-prepared-delete > span:first-child {
  display: inline-block;
  padding: 2px 9px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 2px;
}
.strategy ul {
  padding-left: 0px;
  margin-left: 0px;
  list-style: none;
}
.strategy ul li {
  display: flex;
  align-items: center;
}

.strategy-form-inner input {
  width: 100%;
}
.learnings ul,
.nextsteps ul {
  padding-left: 0px;
  margin-left: 0px;
}
.learnings ul li,
.nextsteps ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.learnings ul li .learning-actions {
  display: inline-flex;
  gap: 15px;
}
.learnings ul li .learning-actions > span {
  cursor: pointer;
}
.visual-box-inner .visual-box > .control {
  color: #4233ff;
}
.visual-box-inner .visual-box > .variant {
  color: #33c1ff;
}
.visual-box-inner .visual-box > div {
  width: 100%;
  padding: 5px 5px;
  text-align: center;
}
.visual-box-inner .visual-box > div label {
  color: #000;
  font-size: 12px;
  font-weight: 400;
}
.calculated-boxes-inner > div > div,
.calculated-boxes-inner > div > label {
  font-weight: 400;
}
.visual-box-inner .visual-box > div > .value {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.visual-box-inner .visual-box > div > .value svg {
  margin-left: 4px;
  font-size: 12px;
  cursor: pointer;
  fill: #959595;
}
.visual-box-inner .visual-box > div > .value svg:hover {
  fill: green;
}
.segments-drops {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topbarstickyadmin {
  position: fixed;
  top: 0;
  top: 0;
  right: 0;
  left: 0;
  margin-left: 315px;
  background-color: #fff;
  padding: 5px 0px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.testlive-calendar .rdrMonth,
.testlive-calendar .rdrCalendarWrapper {
  width: 100%;
}
.topbarstickyadmin > button {
  background-color: #f1f1f1;
  margin: 0px 2px 2px;
}
/* .livetests-heading {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #0d6efd;
  margin-bottom: 15px;
} */
.dimension-metrics-dropdowns {
  padding: 10px 5px;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.segments-drops > div {
  width: 100%;
  max-width: 33%;
}
/* .segments-drops .dropdown-content .panel-content {
  border: none !important;
  box-shadow: none !important;
}
.segments-drops .dropdown-content .panel-content .options {
  margin-top: 0px !important;
  border: none !important;
}
.segments-drops .dropdown-content {
  top: -270px !important;
  min-height: 260px !important;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
} */
.datepicker-wrapper .buttons-operations button {
  border-radius: 5px;
  min-width: 100px;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
}
.datepicker-wrapper button.apply {
  background-color: #003ed4;
  color: #fff;
}
.datepicker-wrapper button.cancel {
  border: 1px solid #727272;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
}

/* Retainer Management */
.retainer-management-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.retainer-management-form > div {
  padding: 25px 20px;
  max-width: 100%;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  /* flex: 0px; */
  align-items: center;
  grid-gap: 2%;
}
.retainer-management-form > div > .fields-wrapper {
  margin-bottom: 0px;
  flex: 1 1 0px;
}

@media (max-width: 1023px) {
  .retainer-management-form > div {
    max-width: 48%;
  }
}
@media (max-width: 767px) {
  .retainer-management-form > div {
    max-width: 100%;
  }
}
/* Retainer Management */
