.field-wrapper{
    margin-bottom: 18px;
    label{
        color: #FFF;
        font-size: var(--default-fontsize);
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
        display: inline-flex;
        + .sub-label{
          color: var(--desc-color-lite);
        }
    }
    .input-wrapper{
        border-radius: 2px;
        border: 1px solid var(--border-color);
        // background: var(--bg-color2);
        max-width: 388px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--value16);
        width: 100%;
        input, textarea{
            background-color: transparent;
            width: 100%;
            border: none;
            outline: 0;
            color: var(--desc-color);
            &:disabled{
                color: var(--desc-color-lite);
                cursor :not-allowed;
            }
        }
        &.full-width{
            max-width: 100%;
        }
        &.small{
            padding: var(--value8);
        }
        &.medium{
            padding: var(--value12);
        }
    }
    .checkbox-wrapper{
        input{
            position: absolute; // take it out of document flow
            opacity: 0; // hide it
            & + label {
                position: relative;
                cursor: pointer;
                padding: 0;
              }
            
              // Box.
              & + label:before {
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 20px;
                height: 20px;
                background: white;
              }
            
              // Box hover
              &:hover + label:before {
                background: #f35429;
              }
              
              // Box focus
              &:focus + label:before {
                box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
              }
            
              // Box checked
              &:checked + label:before {
                background: #f35429;
              }
              
              // Disabled state label.
              &:disabled + label {
                color: #b8b8b8;
                cursor: auto;
              }
            
              // Disabled box.
              &:disabled + label:before {
                box-shadow: none;
                background: #ddd;
              }
            
              // Checkmark. Could be replaced with an image
              &:checked + label:after {
                content: '';
                position: absolute;
                left: 5px;
                top: 9px;
                background: white;
                width: 2px;
                height: 2px;
                box-shadow: 
                  2px 0 0 white,
                  4px 0 0 white,
                  4px -2px 0 white,
                  4px -4px 0 white,
                  4px -6px 0 white,
                  4px -8px 0 white;
                transform: rotate(45deg);
              }
        }
        + .sub-label{
            color: var(--desc-color-lite);
        }
    }
    .fogotpassword{
        text-align: right;
        margin-top: 8px;
        a{
        color: var(--color-green);
        font-size: var(--default-fontsize);
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-decoration-line: underline;
        }
        
    }
}