.tests-filter-wrapper, .report-timeline-wrapper{
    column-gap: 1.8%;
    margin-bottom: var(--default-margin-bottom);
    button{
        *{
            text-transform: none;
            font-weight: var(--font-600);
        }

    }
}
.results-filter-wrapper{
    padding: 0px 10px;
    margin-bottom: var(--default-margin-bottom);
    &>div{
        column-gap: 1.8%;
        &>div{
            column-gap: 1.08%;
            button{
                min-width: max-content
            }
            svg{
                path{
                    fill: var(--icons-color);
                }
            }
        }
    }
}
.reports-list-inner{
    row-gap: var(--default-row-gap);
    column-gap: 1.06%;

}
.timeline-filters{
    column-gap: 1.06%;
    .months-list{
        column-gap: 1.06%;
        span{
            cursor: pointer;
            padding: var(--tablehead-padding);
            &.active{
                background: var(--active-bg);
                color: var(--activeicons-color);
            }
        }
    }
    .select-component .conversion-select__control {
        flex-wrap: nowrap;
    }
}