.report-evalution-info{
    border-radius: 2px;
    border: 2px solid var(--border-color);
    padding: var(--card-padding);
    row-gap: var(--value16);
    margin-top: var(--default-margin-bottom);
    .report-evalution-list{
        column-gap: var(--value8);
        h3{
            font-size: var(--xl-fontsize);
            color: var(--desc-color);
            font-weight: var(--font-800);
            margin-bottom: var( --value8);
            +div{
                margin-bottom: var(--value16);
            }
        }
        p{
            font-size: var(--default-fontsize);
            color: var(--desc-color);
            font-weight: var(--font-400);
            line-height: normal;
            b{
                font-weight: var(--font-800);
            }
        }
    }
}