h1{
    color: var(--desc-color);
    font-size: var(--heading-size);
    margin-bottom: var(--default-margin-bottom);
}
h2{
    color: var(--desc-color);
    font-size: var(--value24);
    margin-bottom: var(--default-margin-bottom);
}
.conversion-performance{
    .p-card-flex{
            /* column-gap: 12px; */
    // flex-wrap: wrap;
    // column-gap: 1.06%;
    &.admin-performance-overview-cards{
        column-gap:1%;
        row-gap: 10px;
        .p-card{
            max-width: 24%;
            width: 100%;
        }
    }
    gap: var(--default-row-gap);
        .p-card{
            border-radius: 2px;
            border: 2px solid var(--border-color);
            background: var(--bg-color3);
            // padding: 12px 12px;
            // max-width: 100%;
            // width: 100%;
            .p-card-inner{
                display: flex;
                flex-direction: column;
                row-gap: var(--default-row-gap)
            }
            .p-card-top{
                column-gap: 1.06%;
                span{
                    &.change-in{
                        color: var(--color-green);
                        font-size: var(--large-fontsize);
                        font-weight: var(--font-600);
                        margin-bottom: 9px;
                        display: inline-flex;
                        align-items: center;
                        svg{
                            path{
                                fill: var(--desc-color-lite)
                            }
                        }
                    }
                }
                p{
                   color: var(--desc-color-lite);
                    // opacity: 0.4;
                    font-weight: var(--font-600);
                    font-size: var(--large-fontsize);
                    min-height: 28px;
                    line-height: normal;
                }
            }
            .p-card-bottom{
                column-gap: 1.06%;
                span{
                    &.prepost{
                        color: var(--desc-color-lite);
                        font-size: 24px;
                        font-weight: 800;
                    }
                    &.value{
                        color: var(--desc-color);
                        font-size: 44px;
                        font-weight: 700;
                        line-height: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            &.active{
                background-color: var(--color-neon);
                border-color: var(--color-neon);
                .p-card-top{
                    span{
                        &.change-in{
                            color: var(--desc-color-dark);
                            svg{
                                path{
                                    fill: var(--desc-color-dark);
                                }
                            }
                        }
                    }
                    p{
                        color: var(--desc-color-dark);
                    }
                }
                .p-card-bottom{
                    span{
                        &.prepost{
                            color: var(--desc-color-dark);
                        }
                        &.value{
                            color: var(--desc-color-dark);
                        }
                        // &:last-child{
                        //     color: var(--desc-color-dark);
                        // }
                        // &:first-child{
                        //     color: var(--desc-color-dark);
                        // }
                    }
                }
            }
        }
    }
    .performance-cta{
        margin-top: 40px;
    }
    button{
        border: none;
        color: var(--bg-color2);
        background-color: var(--bg-color4);
        svg{
            path{
                fill: var(--bg-color2);
            }
        }
    }
}

