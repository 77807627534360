.new-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .new-menu-items{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        height: 100%;
        .new-menu-item{
            display: flex;
            align-items: center;
            .new-menu-item-icon{
                margin-right: 10px;
            }
            .new-menu-item-text{
                font-size: 14px;
                font-weight: 500;
                color: #000;
            }
        }
        ul{
            list-style: none;
            margin: 0px 0px;
            padding: 0px 0px;
            li{
                a{
                    display: inline-flex;
                    align-items: center;    
                    color: #FFF;
                    font-family: "SF Pro Display";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    column-gap: 8px;
                    text-decoration: none;
                    text-transform: capitalize;
                    cursor: pointer;
                    .name-initial{
                        height: 22px;
                        width: 22px;
                        text-transform: uppercase;
                        display: inline-flex;
                        align-items: center;
                        font-family: "SF Pro Display";
                        justify-content: center;
                        color: #070707;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 22px; /* 183.333% */
                        background-color: #00FF8D;
                        border-radius: 100%;
                    }
                }

            }
        }
    }
}