.smart-heading{
    color: #F7FFF7;
    font-family: "SF Pro Display";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 48px;
}

@media (max-width: 768px){
    .smart-heading{
        font-size: 24px;
        margin-bottom: 24px;
    }
}