.search-wrapper{
    margin-bottom: var(--default-margin-bottom);
    .search-inner{
        column-gap: 1.06%;
        .field-wrapper{
            width: 100%;
            margin: 0px 0px;
            .input-wrapper{
                width: 100%;
                max-width: 100%;
                &>span{
                    display: inline-flex;
                    svg{
                        path{
                            fill: var(--icons-color);
                        }
                    }
                }
            }
        }
    }
}