.variants-list-inner {
    display: flex;
    align-items: center;
    column-gap: 1%;
    justify-content: space-between;
    margin-bottom: 5px;

    &>div {

        // flex: 1 1 0;
        &.variant-ctas {
            display: inline-flex;
            column-gap: 4px;
        }

        &.variant-options {
            a {
                color: #f6f6f6;
                font-weight: 400;
                font-size: 18px;
                font-weight: 500;
                line-height: 110%;
                margin-bottom: 0px;
            }
        }

        &.variant-traffic {
            max-width: 100px;
        }

        &.variant-name {
            width: 100px;
        }

        h4 {
            color: #f6f6f6;
            font-weight: 400;
            font-size: 18px;
            font-weight: 500;
            line-height: 110%;
            margin-bottom: 0px;
        }

        .fields-wrapper {
            margin: 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 4px;
            color: #fff;

            input.field-control.darkinput {
                max-width: fit-content;
            }
        }
    }
}

.experiment-box-footer {
    button {
        margin-left: auto;
    }
}