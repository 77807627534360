.report-card{
    max-width: 32.2%;
    width: 100%;
    border-radius: 2px;
    border: 2px solid var(--border-color);
    padding: var(--card-padding);
    // background-color: var(--bg-color5);
    &.active{
        border-color: var(--color-neon);
    }
    .report-card-header{
        border-bottom: 1px solid var(--border-color);
        h2{
            text-align: center;
            font-size: var(--default-fontsize);
            font-weight: var(--font-400);
            overflow: hidden;
    text-overflow: ellipsis;
        }
    }
    .report-card-content{
        padding: var(--value16) 0px;
        row-gap: var(--default-row-gap);
        >div{
            // width: 33%;
            // flex: 1 1 0;
            // min-width: 30%;
            &:last-child{
                width: 100%;
            }
            >h4{
                letter-spacing: 0.4px;
                text-transform: uppercase;
                line-height: normal;
                font-weight: var(--font-800);
                font-size: var(--value10);
                color: var(--desc-color-lite);
                margin-bottom: 4px;
            }
            >p{
                color: var(--desc-color);
                font-weight: var(--font-400);
                font-size: var(--default-fontsize);
                svg{
                    margin-right: 3px;
                }
                &.conversionRate{
                    span{
                        svg{
                            display: none;
                        }
                        &.green{
                            svg{
                                display: inline-flex;
                                path{
                                    fill: var(--color-neon);
                                }
                            }
                        }
                        &.red{
                            svg{
                                display: inline-flex;
                                transform: rotate(180deg);
                                path{
                                    fill: var(--color-red);
                                }
                            }
                        }
                        &.yellow{
                            svg{
                                display: inline-flex;
                                path{
                                    fill: var(--color-yellow);
                                }
                            }
                        }
                    }
                }
               
            }
            &.learning{
                >p{
                    font-size: var(--large-fontsize);
                    line-height: normal;
                }
            }
        }
        a{
            &.editReport{
                color: var(--color-neon);
                margin-left: 2px;
            }
        }
    }
    svg{
        path{
            fill: var(--icons-color);
        }
    }
}