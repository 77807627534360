.sequential-results{
    border: 2px solid var(--border-color);
    margin-bottom: var(--default-margin-bottom);
    padding: var(--value12);
    column-gap: var(--default-row-gap);
    border-radius: 2px;
    &>div{
        color: var(--desc-color);
        font-size: var(--default-fontsize);
        text-wrap: nowrap;
        span{
            color: var(--color-green);
        }
    }
}
.sequential-cards-wrapper{
    column-gap: 1.06%;
}