.t-card-flex{
    gap: var(--default-row-gap);
    // column-gap: 1.06%;
    >div{
        flex: 1 1 0;
       
    }
    .success-rt{
        border: 2px solid var(--border-color);
        padding: 18px var(--value16);
        display: flex;
        flex-direction: column;
        // row-gap: 85px;
        justify-content: space-between;
        // min-height: 234px;
        .card-top{
            p{
                font-size: var(--default-fontsize);
                color: var(--desc-color);
            }
            span{
                color: var(--color-green);
                font-size: 44px;
                font-weight: var(--font-600);
                line-height: normal;
            }
        }
    }
    .insights-crds{
        // max-width: 32.2%;
        // width: 100%;
        column-gap: 3%;
        row-gap: var(--default-row-gap);
        
        .insight-card{
            min-height: 74px;
            width: 100%;
            padding: 8px 8px;
            flex-direction: column;
            border-radius: 2px;
            border: 2px solid var(--border-color);
            justify-content: space-between;
            .card-top{
                p{
                    font-size: var(--large-fontsize);
                    font-weight: var(--font-600);
                    color: var(--desc-color);
                }

            }
            .card-bottom{
                font-size: var(--xl-fontsize);
                color: var(--color-green);
                font-weight: var(--font-600);
            }
        }
    }
}

.test-performance-overview-wrapper{
    >div{
        flex: 1 1 0;
        // max-width: 50%;
        // width: 100%;
    }
    .business-performance-overview-wrapper{
        >div{
            // flex: 1 1 0;
        max-width: 50%;
        width: 100%;
        }
    }
    .sucess-rate-insights-wrapper{
        border-width: 2px;
        border-radius: 2px;
        // >div{
        //     flex: 1 1 0;
        // }
        .test-insights{
            row-gap: 7px;
            >div{
                border-radius: 2px;
                gap: 14px;
                padding: 8px;
                min-width: 120px;
                p{
                    font-weight: var(--font-600);
                    line-height: normal;
                    font-size: 14px;
                }
                .card-bottom{
                    color: var(--color-neon);
                    font-size: 18px;
                    font-weight: var(--font-600);
                    line-height: normal;
                }
            }
        }
        .success-rt{
            .card-top{
                p{
                    font-size: var(--default-fontsize);
                    color: var(--desc-color);
                    font-size: 18px;
                    font-weight: var(--font-600);
                    line-height: normal;
                }
                span{
                    color: var(--color-green);
                    font-size: 44px;
                    font-weight: var(--font-700);
                    line-height: normal;
                }
            }
            .card-bottom{
                p{
                    color: var(--desc-color);
                font-size: 18px;
                font-weight: var(--font-400);
                line-height: normal;
                }
            }
        }
    }
}