$mobile: 768px;
.smart-proj-overview-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 24px;
    &>div{
        flex: 1 1 0;
        &>h3{
            color: #F7FFF7;
            font-family: "SF Pro Display";
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        &>.proj-overview-data{
            margin-top: 24px;
            &>div{
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 2%;
                padding: 16px 0px;
                border-bottom: 1px solid #2C2C2C;
                h4{
                    color: rgba(255, 255, 255, 0.40);
                    font-family: "SF Pro Display";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    letter-spacing: 0.64px;
                    text-transform: uppercase;
                    margin: 0 0;
                }
                .proj-value{
                    color: #FFF;
                    text-align: right;
                    font-family: "SF Pro Display";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

@media (max-width: $mobile){
    .smart-proj-overview-inner{
        &>div{
            &>.proj-overview-data{
                &>div{
                    h4{
                        font-size: 10px;
                    }
                    .proj-value{
                        font-size: 14px;
                    }
                }
            }
        }
    }   
}