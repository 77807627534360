.bar-chart-grid{
    display: flex;
    flex-direction: column;
    row-gap: 31px;
    padding-top: 160px;
    padding-bottom: 0px;
    position: relative;
    >svg{
        position: absolute;
        top: 0;
        left: 0;
        width: 85.5%;
        margin: auto;
        height: 100%;
        right: 0;
        bottom: 0;
    }
    .bar-chart-grid-box{
        display: flex;
        align-items: center;
        column-gap: 78px;
        &.barchart-points-wrapper{
            padding-top: 50px;
            .barchart-points{
                display: flex;
                align-items: center;
                justify-content: space-between;
                span{
                    font-size: var(--value10);
                    font-weight: var(--font400);
                    color: var(--desc-color-grey);
                }
            }
        }
        .barvariant{
            font-size: var(--value10);
            font-weight: var(--font400);
            white-space: nowrap;
            color: var(--desc-color-grey);
            max-width: 52px;
            width: 100%;
        }
        .barvalue{
            font-size: var(--default-fontsize);
            font-weight: var(--font700);
            white-space: nowrap;
            color: var(--desc-color);
        }
        .bar-wrapper{
            width: 100%;
            .bar{
            height: 16px;
            border-radius: 2px;
            background-color: red;
            }
        }
    }
}
