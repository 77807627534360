.sharable-report-detail{
    .report-name-heading{
        row-gap: var(--default-row-gap);
    }
    h1.m-0{
        margin-bottom: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .conclusion-factors-wrapper{
        margin: var(--default-margin-bottom) auto;
        column-gap: 1.06%;
        white-space: nowrap;
        row-gap: var(--default-row-gap);
    }
}