.select-component{
    display: flex;
    align-items: center;
    column-gap: 8px;
    border-radius: 2px;
    span.icon{
        display: inline-flex;
        svg{
            path{
                fill: var(--icons-color);
            }
        }
    }
    .conversion-select__option{
        background-color: transparent;
        cursor: pointer;
        overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
    }
    .conversion-select__control{
        min-height: auto;
        border-radius: 0px;
        background-color: transparent;
        box-shadow: none;
        border: none;
        column-gap: 8px;
        flex-wrap: nowrap;
        &:hover, &:focus{
            border: none;
        }
    } 
    .conversion-select__option--is-selected,.conversion-select__option--is-focused{
        background-color: var(--active-bgselect);
        color: var(--color-green);

    }
    
    .conversion-select__menu-list{
        background-color: var(--bg-color2);
    }
    .conversion-select__single-value{
        color: var(--desc-color);
    }
    .conversion-select__value-container, .conversion-select__input-container{
        padding: 0px 0px;
        font-size: var(--default-fontsize);
    }
    .conversion-select__value-container{
        .conversion-select__multi-value{
            padding: 4px var(--value12);
            background-color: var(--bg-color2);
            color: var(--desc-color);
            .conversion-select__multi-value__label{
                color: var(--desc-color);
            }
        }
    }
    &:not(.user-select){
        .conversion-select__value-container{
            max-width: 165px;
            min-width: 135px;
            &.conversion-select__value-container--is-multi{
                max-width: 100%;
            }
        }
    }
    &.user-select{
        .conversion-select__value-container{
            max-width: 130px;
            min-width: fit-content;
            &.conversion-select__value-container--is-multi{
                max-width: 100%;
            }
        }
        .conversion-select__menu-list{
            min-width: fit-content;
        }
    }
    .conversion-select__indicator-separator{
        display: none;
    }
    .conversion-select__indicator{
        color: var(--desc-color);
        padding: 0px;
        &:hover{
            color: var(--desc-color);
        }
    }
}

@media (max-width:1023px){
    .select-component{
        &:not(.user-select){
            .conversion-select__value-container{
                max-width: 150px;
                min-width: 100px;
            }
        }
    }   
}