.store-ideas-inner .store-ideas-head,
.store-ideas-inner .store-ideas-search-bar {
  display: flex;
  justify-content: space-between;
}
.store-ideas-head h3,
.store-ideas .ideas-client-name > h3 {
  font-weight: 400;
  font-size: 50px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--base-white-color);
}

.ideas-client-name > h3 {
  margin-bottom: 0px;
}
.store-ideas-head {
  margin-bottom: 29px;
}

/* do not copy same as addsheet link page search bar starts  */
.store-ideas-search-bar .input-search-wrapper {
  border-bottom: 0.5px solid #b9b9b9;
  padding-bottom: 9.5px;
  width: 100%;
}
.store-ideas-search-bar .input-search-wrapper svg {
  position: absolute;
}
.store-ideas-search-bar .store-ideas-search {
  background-color: transparent;
  border: none;
  outline: none;
  line-height: 130%;
  width: 100%;
  font-size: 22px;
  padding-left: 52px;
  font-weight: var(--base-font-weight);
  color: var(--base-white-color);
}
.store-ideas-search-bar .store-ideas-search::placeholder {
  color: #727272;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: var(--base-font-weight);
}

.store-ideas-search-bar .store-ideas-search:focus {
  outline: none;
}
.store-ideas-search-bar .sort-options {
  margin-left: 12px;
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 8.94%;
}
.store-ideas-search-bar .sort-options > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: var(--base-white-color);
  margin-right: 16px;
}
.store-ideas-inner .links-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  margin-top: 24px;
}

section.store-ideas .client-data-wrapper {
  margin-top: 26px;
}

section.store-ideas .client-details {
  display: flex;
  justify-content: space-between;
}
section.store-ideas .client-details img {
  max-width: 100%;
}
section.store-ideas .client-dropdown img {
  max-width: 100%;
}
.section-heading {
  font-size: var(--heading-size);
  font-weight: var(--base-font-weight);
  text-transform: capitalize;
  line-height: var(--heading-line-height);
  color: var(--secondary-white-color);
}
.client-details .section-heading {
  margin-bottom: 17px;
}

.ideas-client-wrapper {
  margin: 40px 0px;
}
.ideas-client-img {
  margin-bottom: 12px;
}
.ideas-client-img img {
  object-fit: contain;
  max-width: 140px;
}

.ideas-client-note {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-note-description {
  font-weight: 400;
  font-size: 26px;
  line-height: 110%;
  color: #b9b9b9;
  margin-top: 20px;
}

.store-ideas-table {
  margin-top: 36px;
  /* margin-bottom: 60px; */
}
.store-ideas-table__g {
  margin-top: 20px;
}
.store-ideas-table__g .store-ideas-table-data {
  font-size: 16px;
  padding: 12px 8px;
  align-self: stretch;
  min-height: 60px;
}
.store-ideas-table-headers {
  background-color: #222222;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  max-width: 100%;
}
.store-ideas-table-headers > h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 3.8%;
  /* max-width: calc(100% / 5); */
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #b9b9b9;
  border-right: 0.5px solid #727272;
  padding: 6px;
  align-self: stretch;
  text-align: center;
  margin-bottom: 0px;
  transition: 0.3s all ease-in-out;
}

.store-ideas-table-headers-Inter {
  min-width: 12.8% !important;
}

.store-ideas-table-headers > h4 > span {
  display: inline-flex;
  margin-left: 12px;
}

.store-ideas-table-single-row .store-ideas-table-data {
  border-left: 0.5px solid #404040;
  text-align: center;
  word-break: break-word;
}

.store-ideas-table-single-row > h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 3.8%;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #b9b9b9;
  border-right: 0.5px solid #727272;
  padding: 6px;
  align-self: stretch;
  text-align: center;
  margin-bottom: 0px;
  transition: 0.3s all ease-in-out;
}

.store-ideas-table-headers .ideas-table-sno,
.store-ideas-table-rows .ideas-table-sno {
  width: 100%;
  max-width: 51px;
  text-align: center;
  border-left: none;
  justify-content: center;
  margin-bottom: 0px;
}
/* .select::after {
  content: url(../assets/chevron\ icon.svg);
  position: absolute;
  right: 8px;
} */

/* .store-ideas-table-headers .ideas-table-sno {
  border-right: 0.5px solid #727272;
} */

.store-ideas-table-headers h4:last-child,
.store-ideas-table-single-row .store-ideas-table-data-btn {
  border-right: none;
}

.store-ideas-table-rows .store-ideas-table-single-row {
  display: flex;
  align-items: center;
  border: 0.5px solid #404040;
  border-top: none;
}
.store-ideas-table-single-row > * {
  width: 26%;
  /* max-width: calc(100% / 5); */
  padding: 16px 8px;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #ebebeb;
  position: relative;
}
.store-ideas-table .store-ideas-table-data-btns {
  padding: 11px 8px;
}

.store-ideas-table-data.text-center {
  text-align: center;
}
.store-ideas-table-data-btn {
  background-color: var(--primary-color);
  border-radius: 4px;
  padding: 5px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 173px;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #ebebeb;
  margin: 0 auto;
  cursor: pointer;
}
.store-ideas-table-data-btn__positive {
  background-color: rgba(50, 215, 75, 0.3);
}
.store-ideas-table-data-btn__negative {
  background-color: rgba(255, 69, 58, 0.3);
}
.add-hypothesis-store-ideas {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  cursor: pointer;
}
.add-hypothesis-store-ideas-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #f6f6f6;
  margin-left: 8px;
}
.store-ideas-table__g .store-ideas-table-single-row {
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.store-ideas-table__g .store-ideas-table-single-row.tested {
  background: #282626;
}
.store-ideas-table__g.store-ideas-table .store-ideas-table-data {
  min-height: fit-content;
  display: flex;
  align-items: center;
}
/* .store-ideas-table__g .store-ideas-table-headers h4:last-child {
  padding: 0;
  text-align: center;
  line-height: 100%;
} */
h4.psychological-trigger {
  flex-wrap: wrap;
  row-gap: 3px;
  padding: 0px 5px;
}
.store-ideas-table .yes-no-select .acc-newselectwrapper .acc-newselect__menu {
  min-width: 70px;
}
h4.psychological-trigger > p {
  max-width: min-content;
  margin-bottom: 0px;
}
.store-ideas-table-data .test-compare-img img {
  width: 20px;
  height: 20px;
}
.store-ideas-table-data .test-compare-img > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #727272;
  margin-top: 5px;
  text-align: center;
}
.store-ideas-table-data .link-icon,
.store-ideas-table-data .test-compare-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.store-ideas-table-data .link-icon a,
.store-ideas-table-data .test-compare-img a {
  color: #fff;
  text-decoration: none;
}
/* .store-ideas-table-data .test-compare-img {
  margin-right: 24px;psychological-trigger
} */
.store-ideas-table__g .evidence-group p {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #727272;
  margin-top: 5px;
  margin-bottom: 0px;
}
.store-ideas-table__g .evidence-group svg {
  width: 24px;
  height: 24px;
}
.store-ideas-table__g .insight-trigger-list {
  gap: 4px;
  flex-wrap: wrap;
  display: flex;
}

.store-ideas-table__g .store-ideas-table-headers h4:nth-child(2),
.store-ideas-table__g .store-ideas-table-data:nth-child(2) {
  width: 100%;
  text-align: center;
}

/* .store-ideas-table-data:nth-child(10) {
  max-width: 16.6%;
  min-width: 12.8%;
} */

.store-ideas-table__g .store-ideas-table-headers h4:last-child,
.store-ideas-table__g .store-ideas-table-data:last-child {
  width: 100%;
  text-align: center;
}
.store-ideas-table__g .evidence-group {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
  height: 100%;
}

.add-new-condition-btn {
  display: inline-block;
  background: #003ed4;
  border-radius: 5px;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--base-white-color);
  width: 300px;
  padding: 18px 0px;
  text-align: center;
  margin-top: 40px;
  /* margin-bottom: ; */
  margin: 40px 0px 32px;
}
.store-ideas-table
  .store-ideas-table-data:nth-last-child(2)
  .acc-newselectwrapper {
  max-width: fit-content;
}
/* STORE ideas CSS MAIN  ends */

/* STYLES FOR REACT SELECT WHEN IT WILL BE IMPLEMENTED IN THE CODEBASE CLIENT  */
.store-ideas-table__g .acc-newselectwrapper {
  width: 100%;
}

.store-ideas-table__g .acc-newselectwrapper .acc-newselect__control {
  border: none;
  min-height: 100%;
}
.store-ideas-table__g
  .acc-newselect__indicators
  > .acc-newselect__indicator:last-child {
  width: 24px;
  margin-left: 6px;
}
.store-ideas-table__g .acc-newselect__value-container {
  padding: 0px;
}
.store-ideas-table__g .acc-newselect__input-container {
  margin: 0px;
  padding: 0px;
}
.store-ideas-table__g
  .acc-newselect__control--menu-is-open
  .acc-newselect__input-container,
.store-ideas-table__g
  .acc-newselect__control--menu-is-open
  .acc-newselect__placeholder {
  padding-left: 10px;
}
.store-ideas-table__g::-webkit-scrollbar {
  background-color: #404040;
  border-radius: 10px;
  height: 3px;
}
.store-ideas-table__g::-webkit-scrollbar-thumb {
  background-color: #727272;
  height: 3px;
  border-radius: 5px;
}
@media (max-width: 1279px) {
  .store-ideas-table__g {
    /* width: 100vw; */
    overflow-x: auto;
  }
  .store-ideas-table-headers,
  .store-ideas-table-rows {
    min-width: 1000px;
  }
  .sidebar-active + .client-layout-content .store-ideas-table-headers h4 {
    font-size: 17px;
  }
}

@media (max-width: 1023px) {
  .store-ideas-table-headers,
  .store-ideas-table-rows {
    min-width: 1000px;
  }
  .store-ideas-table-headers > h4,
  .store-ideas-table-data {
    font-size: 17px;
    max-width: calc(100% / 5);
  }
}

@media (max-width: 768px) {
  .store-ideas-table-data-btn {
    font-size: 16px;
  }
  .store-ideas-table__g .store-ideas-table-data {
    font-size: 15px;
  }
  .store-ideas-head h3,
  .store-ideas .ideas-client-name > h3 {
    font-size: 40px;
  }
  .store-ideas .section-heading {
    font-size: 32px;
  }
  .client-note-description {
    font-size: 20px;
  }
  .add-new-condition-btn {
    font-size: 16px;
    width: 230px;
    padding: 11px 0px;
  }
}

#idea-model {
  max-width: 95% !important;
}

.next-btn-idea {
  background-color: var(--primary-color);
  min-width: 95px !important;
  float: right;
}

.done-btn-idea {
  background-color: var(--primary-color);
  color: white;
  width: 90px;
  height: 45px;
  margin-left: 50%;
  border: none;
  border-radius: 10px;
}
.add-idea-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.result-idea {
  text-align: center;
  padding-top: 8px;
}
.idea-prio-head {
  margin-top: 10px;
}
.idea-medel-pt-nm {
  padding-top: 8px;
}
