.projected-calculated-header{
    display: flex;
    align-content: center;
    justify-content: space-between;
    column-gap: 20px;
    &>div{
        display: inline-flex;
        column-gap: 5px;
        flex-wrap: wrap;
        row-gap: 6px;
    }
}

@media (max-width: 768px){
    .projected-calculated-header{
        flex-direction: column;
        &.overview{
            row-gap: 20px;
        }
        &>div{
            width: 100%;
            &.detail-proj-type-buttons{
                &>button{
                    max-width: 100%;
                width: 100%;
                }
            }
        }
    }
}