$mobile: 768px;
.projected-effect-data{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
.projected-effect-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 2px;
    border: 2px solid #2C2C2C;
    background: #070707;
    &>h3{
        color: rgba(255, 255, 255, 0.40);
        font-family: "SF Pro Display";
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.48px;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        margin: 0 0;
        &>span:first-child{
            margin-right: 8px;
        }
        &>span:last-child{
            display: inline-flex;
            align-items: center;
            color: #00FF8D;
            font-family: "SF Pro Display";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    &>div{
        display: inline-flex;
        align-items: center;
        column-gap: 4px;
        span{
            &.currency{
                color: rgba(255, 255, 255, 0.40);
                font-family: "SF Pro Display";
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
            }
            &.price-value{
                color: #FFF;
                font-family: "SF Pro Display";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }
}

@media (max-width: $mobile){
    .projected-effect-card{
        &>div{
            span{
                &.price-value{
                    font-size: 12px;
                }
            }
        }
        > h3{
            font-size: 10px;
            &>span{
                &:first-child{
                    margin-right: 2px;
                }
                &:last-child{
                    font-size: 8px;
                }
                svg{
                    height: 14px;
                    width: 14px;
                }
            }
        }
    } 
}