.business-case-validation-wrapper{
    .business-case-data{
        width: 100%;
        border-radius: 2px;
        border: 2px solid var(--border-color);
        padding: var(--card-padding);
        row-gap: var(--default-row-gap);
        background: var(--bg-color3);
        column-gap: 1.06%;
        margin-bottom: var(--default-margin-bottom);
        .metrics-card{
            max-width: 47.3%;
            width: 100%;
            ul {
                li {
                    padding: var(--value16) 0px;
                    h3{
                        margin-bottom: 0px;
                    }
                }
            }
        }
        .business-case-text{
            width: 100%;
            border-radius: 2px;
            border: 2px solid var(--border-color);
            padding: var(--card-padding);
            row-gap: var(--default-row-gap);
            background: var(--bg-color);
            column-gap: 1.06%;
            ul {
                row-gap: var(--value12);
                li {
                    column-gap: 1.06%;
                    line-height: normal;
                    color: var(--desc-color-grey);
                }
            }
        }
    }
    .filter-labels{
        column-gap: 1.06%;
        row-gap: var(--value12);
        button{
            color: var(--desc-color);
            background-color: var(--bg-color);
            cursor: pointer;
            &.active{
                color: var(--activeicons-color);
                background-color: var(--active-bg);
            
            }
        }
    }
    .splide__pagination{
        display: flex!important;
        position: initial;
        background: rgba(255, 255, 255, 0.36);
        border-radius: 96px;
        overflow: hidden;
        padding: 0px 0px;
        margin: var(--default-margin-bottom) auto;
        li{
            flex:  1 1 0;
            .splide__pagination__page{
                height: 4px;
                opacity: 1;
                background: transparent;
                border: 0;
                border-radius: 0;
                display: block;
                margin: 0px;
                padding: 0;
                transition: transform .2s linear;
                width: 100%;
                border:none!important;
                &.is-active{
                    transform: none!important;
                    background: var(--color-neon)!important;
                    border-radius: 96px;
                }
            }
        }
    }
    .table-content-wrapper{
        transition: border 0.3s ease-in-out;
        border:1px solid transparent;
        .table-heading{
            padding: var(--value12);
            text-align: left;
            h3{
                font-size: var(--large-fontsize);
                font-weight: var(--font-600);
                color: var(--desc-color);
                text-transform: uppercase;
                sup{
                    position: relative;
                    bottom: 5px;
                }
            }
        }
        table{
            tr{
                &.data.total{
                    // border-top: 1px solid #fff;
                 td{
                    font-weight: var(--font-800);
                 }   
                }
            }
        }
        &.activeslide{
            border: 1px solid var(--color-neon);
        }
    }
}

    #businessData, #businesscasewith3mcohort, #futureprojection{
        background-color: var(--bg-color2);
    }
.splidewithoverflow{
    overflow: scroll!important;
}