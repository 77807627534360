.smart-container{
    max-width: 1920px;
    padding: 0px 30px;
    margin: 0px auto;
}

@media (max-width: 768px){
    .smart-container{
        padding: 0px 14px;
    }
}