.graphs-section{
    &>p{
        color: var(--desc-color);
        font-size: var(--value24);
        line-height: normal;
    }
}
.graphs-wrapper{
    margin-top: var(--default-margin-bottom);
    margin-bottom: var(--default-margin-bottom);
    .graphs-toggler{
        column-gap: 1.06%;
        row-gap: var(--default-row-gap);
    }
}