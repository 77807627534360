$mobile:768px;
.m-48{
    margin: 48px 0px;
}
.m-48-top{
    margin-top:48px;
}
.m-48-bottom{
    margin-bottom:48px;
}
.m-36{
    margin: 36px 0px;
}
.m-36-top{
    margin-top:36px;
}
.m-36-bottom{
    margin-bottom:36px;
}
.smart-card-outer{
    border-radius: 2px;
    border: 2px solid #2C2C2C;
    background: rgba(20, 20, 20, 0.60);
    padding: 36px 36px;
}
// .smart-card-body {
//     display: flex;
//     column-gap: 1.9%;
//     justify-content: space-between;
// }
.business-case-form{
    max-width: 36.8%;
    width: 100%;
}
.projected-effect{
 max-width: 61.3%;
 width: 100%;
 .projected-effect-header{
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    h3{
        color: #FFF;
            font-family: "SF Pro Display";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0px 0px;
    }
    &>div{
        display: flex;
        column-gap: 6px;
    }
 }
 .projected-effect-footer{
    margin-top: 24px;
 }
}
.projected-values{
    display: flex;
    column-gap: 6px;
    justify-content: space-between;
}

@media (max-width: $mobile){
    .projected-values, .smart-proj-overview-inner{
        flex-wrap: wrap;
        flex-direction: column;
        row-gap: 20px;
    }
    .smart-proj-overview-inner{
        &>div{
            width: 100%;
        }
    }
    .smart-card-outer{
        padding: 20px 20px;
    }
    .business-case-form , .projected-effect{
        max-width: 100%;
    }
    .calculation-flex-wrapper{
        // flex-wrap: wrap;
    }
    .projected-effect{
        .projected-effect-header{
            flex-direction: column;
    row-gap: 14px;
        }
    }
}