$mobile: 768px;
.dark-bg-2{
    background: hsl(0, 0%, 3%);
}
.list-wrapper-outer{
    padding: 20px 24px;
    border-radius: 3px;
    flex:  1 1 0;
}
.list-heading{
    display: flex;
    column-gap: 19px;
    justify-content: space-between;
    // padding: 20px 24px 0px;
    h3{
        flex:  1 1 0;
        color: rgba(255, 255, 255, 0.40);
        font-family: "SF Pro Display";
        font-size: 10px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        min-height: 24px;
        // text-align: right;
        &:last-child{
            // text-align: right;
        }
    }
}
.list-main-heading{
    padding: 12px 0px 0px;
    h3{
        color: #FFF;
        text-align: right;
        font-family: "SF Pro Display";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        min-height: 24px;
    }
}
.list-data{
    color: #FFF;
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 0px;
}
.calculation-flex-wrapper, .inside-values{
    display: flex;
    column-gap: 19px;
    justify-content: space-between;
}
.calculation-flex-wrapper{
    overflow: auto;
}
.inside-values{
    >div{
        flex: 1 1 0;
        min-width: 85px;
        &:last-child{
           
                // text-align: right;
            
        }
    }
    &.total{
        .list-data{
            font-weight: 700;
        }
    }
}


@media (max-width: $mobile){
    .list-data{
        font-size: 10px;
    }
}