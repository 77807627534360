@font-face {
  font-family: "Neue Haas Grotesk Text Pro";
  src: url("/public/Fonts/NHaasGroteskTXPro-55Rg.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk Text Pro";
  src: url("/public/Fonts/NHaasGroteskTXPro-65Md.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk Text Pro";
  src: url("/public/Fonts//NHaasGroteskTXPro-75Bd.ttf");
  font-weight: 600;
  style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --header-height: 99px;
  --heading-size: 32px;
  --section-padding: 30px 0px;
  --base-white-color: #f6f6f6;
  --secondary-white-color: #ebebeb;
  --base-font-color: #f6f6f6;
  --base-font-weight: 400;
  --heading-line-height: 36px;
  --base-background-color: #070707;
  --base-background-card: #222222;
  --reporting-section-gap: 70px;
  --success-color: #1bc44a;
  --primary-color: #003ed4;
  --danger-color: #b03232;
}
body {
  /* background-color: #121212; */
  font-family: "Neue Haas Grotesk Text Pro", "sans-serif" !important;
  font-weight: var(--base-font-weight);
  color: #ffff;
}
button,
a {
  transition: all 0.3s ease;
}
.btn.btn-primary{
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.hide {
  display: none !important;
}
/* .authlayout-wrapper{
    background-color: #121212;
} */
.header-logo img {
  max-width: 380px;
  width: 100%;
}
.authlayout-inner {
  /* padding-top: var(--header-height); */
}
button.gotonewdashboard{
      /* background-color: var(--base-background-card); */
      border-radius: 5px;
      padding: 15px 4px;
      overflow-wrap: anywhere;
      width: 100%;
      /* max-width: 32%; */
      box-sizing: border-box;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
}
.header-wrapper {
  padding: var(--card-padding);
  z-index: 2;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-color2);
  position: sticky;
  top: 0;
}
.header-wrapper .client-dropdown .fields-wrapper{
  min-width: 170px;
}
.menu-icon {
  flex: 1 1;
  display: flex;
  align-items: flex-end;
  gap: 36px;
}
.notification-bar {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  flex-direction: column;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
  max-width: 320px;
  right: 0;
  top: 0;
  height: 100vh;
  overflow: auto;
}
.setting-bar {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  flex-direction: column;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
  max-width: 320px;
  width: 100%;
  right: 0;
  top: 0;
  height: 100vh;
  overflow: auto;
}
.notification-bar.open {
  visibility: visible;
  transform: none;
}
.setting-bar.open {
  visibility: visible;
  transform: none;
}
.menu-icon span {
  cursor: pointer;
}
.header-logo {
  flex: 1;
  text-align: center;
}
.account-details {
  flex: 1;
}
.ga4-switch-wrapper {
  padding: 10px 0px;
}
.ga4-switch-wrapper .ga4-switch button {
  background-color: #003ed4;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  margin-left: auto;
  margin-right: 0;
  display: flex;
}
.account-details-inner {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  /* align-items: center; */
}
.account-details-inner .closemenu {
  display: none;
  cursor: pointer;
}
.notification,
.dropdown,
.account {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.account {
  align-items: flex-end;
}
.client-average-revenue .rdrDefinedRangesWrapper {
  display: none;
}
.notification-text,
.account-text {
  text-transform: uppercase;
  color: #727272;
  letter-spacing: 1.6px;
  font-size: 14px;
  font-weight: var(--base-font-weight);
  line-height: 18px;
  margin-top: 8.5px;
  display: inline-block;
}
.account .user-info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-img {
  display: inherit;
}
.account .user-name {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.account .user-name::before {
  content: attr(data-initials);
  position: relative;
  display: flex;
  height: 22px;
  width: 22px;
  background-color: var(--color-neon);
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  font-style: normal;
  text-align: right;
  font-size: var(--medium-fontsize);
    border-radius: 22px;
    font-weight: var(--font-700);
    color: var(--bg-color);
}
.manual-evaluation{
  text-align: center;
  padding: 40px 0px;
}
.tab-dropdown-mobile,
.month-dropdown-mobile {
  display: none;
}
.user-name .name {
  font-weight: var(--base-font-weight);
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.3px;
  color: var(--base-white-color);
}
.not-found-report {
  padding: 16px 0px;
}
.not-found-report p {
  color: #fff;
  margin: 0px 0px;
}
.user-name .dropdown {
  margin-left: 5px;
}

.menu-dropdown {
  position: absolute;
  /* top: 40px; */
  /* right: 0px; */
  bottom: -40px;
  top: auto;
  background: #ffffff;
  width: 160px;
  border-radius: 2px;
}

.menu-dropdown .menu-dropdown-list {
  list-style: none;
  margin: 0;
  margin-bottom: 0;
  padding: 0;
  /* height: 40px; */
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
}
.client-login-links{
  max-height: 200px;
  overflow-x: auto;
}
.header-wrapper .header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-wrapper .header-inner svg:not(:last-child) {
  width: 28px;
  height: 28px;
}

.client-layout-content {
  padding: 44px;
  min-height: 100vh;
}
.dark-bg {
  background-color: var(--base-background-color);
}
/* button.variants-info-button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 12px;
} */
/* .authlayout-wrapper.sidebar-is-open .client-layout-content {
  max-width: calc(100% - 220px);
  margin-left: calc(220px);
  padding: 44px;
} */
hr {
  border: none;
  height: 0.7px;
  background-color: #ffff;
}

/* side bar  */
.sidebar-is-open .sidebar {
  width: 100%;
  max-width: 220px;
  min-width: 220px;
}
.sidebar {
  border-right: 1px solid var(--border-color);
  background: var(--bg-color2);
  min-height: 100vh;
  max-height: 100vh;
  overflow-x: none;
  overflow-y: auto;
  position: fixed;
  left: 0;
  transition: all 0.3s ease-in-out;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 0px 9px;
}
.announce-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 40px;
  max-width: 275px;
  left: auto;
  width: 100%;
  background: #003ed4;
  border-radius: 5px;
}
.announce-wrapper .announce-inner:not(:last-child) {
  margin-bottom: 5px;
}
#progress-bar-wrapper {
  margin: 10px 0px;
  height: 18px;
  /* color: rgb(255, 255, 255); */
}
.progress-inner {
  position: relative;
}
.progress-inner .progress-total {
  width: 100%;
  position: absolute;
  max-width: 100%;
  height: 18px;
  background: #f1f1f1;
  border-radius: 14px;
}
.code-snippets > .snippets-overflow {
  border-bottom: 1px dashed;
  padding: 15px 0px 10px;
}
.progress-inner .progress-made {
  max-width: 0%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--color-neon);
  border-radius: 14px;
  height: 18px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--desc-color-dark);
}
.announce-wrapper .announce-inner {
  padding: 4px 12px;
  filter: drop-shadow(0px 4px 6px #052779);
}
.announce-wrapper .announce-inner .announce-title h2 {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 16px;
  line-height: 110%;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 0px;
  padding: 8px 0px 8px;
  cursor: pointer;
}

.announce-wrapper .announce-inner .announce-details {
  border-top: 1px solid #fff;
}
.announce-wrapper .announce-inner .announce-details p {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 14px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 0px;
  margin-top: 8px;
}
.sidebar .sidebar-inner {
  /* display: flex; */
  align-items: center;
}
.seq-values {
  display: flex;
  align-items: center;
  justify-content: normal;
  column-gap: 20px;
  padding: 5px 0px 10px;
}
.seq-values p {
  margin-bottom: 0px;
  color: var(--color-neon);
}
.seq-values p span {
  color: #fff;
  font-weight: bold;
}
ul.sidebar-content {
  padding-left: 0px;
  margin-left: 0px;
  height: 92vh;
  overflow: auto;
}
 ul.minmaxlist{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
ul.minmaxlist a{
  cursor: pointer;
}
.sidebar-is-open .sidebar-list.logo.mobile{
  display: none;
}
.authlayout-wrapper:not(.sidebar-is-open) .sidebar-list.logo{
  display: none;
}
.authlayout-wrapper:not(.sidebar-is-open) .sidebar-list.logo.mobile{
  display: flex;
}
.sidebar-list {
  list-style: none;
  width: 100%;
  max-width: 284px;
  font-size: 18px;
}
.sidebar-list.logo{
  display: flex;
  padding: var(--card-padding);
  justify-content: center;
}
/* .sidebar-list.arrowdown > a:first-child::after{
  content: "";
  position: relative;
  display: inline-flex;
  width: 20px;
  height: 20px;

} */
.header-bar {
  margin: 10px 0px;
}

.user-modal-header-bar {
  margin: 0px 0px 10px;
}
.mobile-menu{
  display: none;
}
.sidebar-list:not(:last-child) {
  /* margin-bottom: 30px; */
}
.header-wrapper .header-inner {
  /* width: 100%;
  max-width: 100%;
  margin-left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--card-padding); */
}
.sidebar-list .submenu {
  display: none;
  padding-left: 30px;
  /* margin-top: 22px; */
}
.sidebar-list .submenu a {
  text-transform: capitalize;;
}
.sidebar-list .submenu a{
  padding: 16px 16px;
}
.sidebar-list .submenu a:not(:last-child) {
  /* margin-bottom: 20px; */
}
.sidebar-is-open .sidebar .sidebar-list a.dropactive + .submenu {
  display: block;
}
.sidebar-list a.active,
.sidebar-list a.dropactive {
  color: var(--color-green);
}
.sidebar-list a svg path{
  fill: var(--base-white-color);
}
.sidebar-list a.active svg path,
.sidebar-list a.dropactive svg path {
  fill: var(--color-green);
}
.sidebar-list a {
  text-decoration: none;
  text-transform: uppercase;
  color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}
.sidebar-list a > span {
  display: flex;
}
.sidebar-list a .sidebar-text,
.sidebar-list a .dropicon {
  display: none;
  line-height: initial;
}
.sidebar-is-open .sidebar .sidebar-list a {
  justify-content: space-between;
  /* white-space: normal; */
}
.sidebar-is-open .sidebar .sidebar-list .icon {
  margin-right: 12px;
}
.sidebar-is-open .sidebar .sidebar-list a .sidebar-text {
  display: inline;
}
.sidebar-is-open .sidebar .sidebar-list a .dropicon {
  display: inline-flex;
}
.sidebar-is-open .sidebar .sidebar-list a .sidebar-text .dropicon svg {
  width: 20px;
  height: 9px;
}
.sidebar::-webkit-scrollbar {
  width: 100%;
}
.sidebar-is-open .authlayout-inner main{
  width: 100%;
  max-width: calc(100% - 220px);
}
.authlayout-inner main{
  margin-left: auto;
  max-width: calc(100% - 81px);
  width: 100%;
}
.sidebar-list a.active svg path {
  fill: var(--color-green);
  stroke: var(--color-green);
}
.sidebar-list a:hover, .dropdown-menu.topbar-dropdown li a:hover {
  color: var(--color-green);
}
.sidebar-list a:hover svg path, .dropdown-menu.topbar-dropdown li a:hover svg path{
  fill: var(--color-green);
  stroke: var(--color-green);
}
.sidebar-list a svg {
  width: 19px;
  height: 19px;
}
.sidebar-list .icon {
  margin-right: 0px;
}

/* .sidebar-active,
.sidebar-is-open .sidebar {
  max-width: 220px;
} */

/* side bar ends  */

/* client stats */
.client-stats {
  display: flex;
  justify-content: space-between;
  /* padding: var(--section-padding) */
  padding: 15px 0px 30px;
}

/* analytics starts  */
.client-analytics {
  width: 100%;
  max-width: 47.62%;
}
.client-logo-wrap .client-logo {
  max-height: initial;
  margin-bottom: 5px;
  margin-top: 15px;
}
.client-logo-wrap .client-logo img {
  max-width: 264px;
  width: 100%;
  max-height: 41px;
  object-fit: contain;
  object-position: left;
}
.clients-inner .tiers-list {
  padding: 20px 0px;
  overflow: auto;
  white-space: nowrap;
  position: relative;
  scrollbar-width: none;
}
.tiers-list::-webkit-scrollbar {
  display: none;
}
.analytics-text {
  font-weight: var(--base-font-weight);
  font-size: var(--heading-size);
  line-height: var(--heading-line-height);
  text-transform: capitalize;
  color: var(--base-font-color);
  margin: 0px 0px 16px 0px;
}
.test-hypothesis-inner .hypothesis-filter {
  display: flex;
  flex-wrap: wrap;
  row-gap: 7px;
  column-gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.hypothesis-filter .single-filter.active,
.hypothesis-filter .single-filter:hover {
  background-color: var(--primary-color);
}
.hypothesis-details-dropdown {
  padding: 5px 0px;
}
.hypothesis-details-dropdown p {
  margin: 0px 0px;

  color: #003ed4;
}
.hypothesis-details-dropdown p span {
  color: #fff;
}
.hypothesis-filter .single-filter {
  padding: 16px 10px;
  background-color: var(--base-background-card);
  border-radius: 5px;
  width: 100%;
  max-width: 15.7%;
  text-transform: capitalize;
  font-weight: var(--base-font-weight);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 150%;
  color: var(--secondary-white-color);
  cursor: pointer;
}

.analytics-cards-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 8px;
  width: 100%;
}

.analytics-cards-list .analytics-card {
  border: 1px solid #404040;
  border-radius: 5px;
  padding: 17.25px 20px;
  width: 100%;
  max-width: 48%;
  min-height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.analytics-card-title {
  font-weight: var(--base-font-weight);
  font-size: 18px;
  line-height: 20px;
  color: var(--base-font-color);
}
.header-bar .header-bar-inner > div:last-child {
  width: 100%;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
}
.analytics-card-active {
  background-color: var(--primary-color);
}
.analytics-card-figures {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.active-filter-wrapper .filter {
  display: flex;
  column-gap: 10px;
}
.active-filter-wrapper .filter .filter-name {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  background-color: #fff;
  padding: 2px 12px;
  border-radius: 5px;
}
.active-filter-wrapper > h4 {
  font-weight: var(--base-font-weight);
  font-size: 18px;
  line-height: 20px;
  color: var(--base-font-color);
  margin: 0px 0px 10px 0px;
}
.active-filter-wrapper .filter .filter-name h5 {
  /* color: #fff; */
  margin: 0px 0px;
  /* color: #fff; */
  font-size: 12px;
  text-transform: uppercase;
}
.active-filter-wrapper .filter .filter-name span {
  cursor: pointer;
}
.active-filter-wrapper .filter .filter-name h5 span {
  font-weight: 700;
  color: #003ed4;
}
/* .header-bar .header-bar-inner h2 {
  font-weight: var(--base-font-weight);
  font-size: 40px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--base-white-color);
} */
/* .header-bar .header-bar-inner h4 {
  font-weight: var(--base-font-weight);
  font-size: 24px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--base-white-color);
} */
ul.pagination li button {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  padding: 0px 0px;
}
ul.pagination li button.previous svg {
  transform: rotate(-180deg);
}
ul.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: flex-end;
  column-gap: 20px;
  margin-bottom: 15px;
}
.header-bar .header-bar-inner button.add,
.client-layout-content > .cta-headers > button {
  width: 100%;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  min-height: 48px;
  text-decoration: none;
  background-color: var(--primary-color);
  color: var(--secondary-white-color);
  border-radius: 5px;
  text-align: center;
  font-weight: var(--base-font-weight);
  font-size: 18px;
  line-height: 130%;
  text-transform: uppercase;
  padding: 18px 18px;
}
.filtertests-tabs .filtertests-tabs-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1%;
  row-gap: 4px;
  flex-wrap: wrap;
}
.filtertests-tabs .filtertests-tabs-inner button {
  background-color: var(--base-background-card);
  border-radius: 5px;
  padding: 10px 25px;
  overflow-wrap: anywhere;
  /* width: 100%;
  max-width: 250px; */
  box-sizing: border-box;
  border: none;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 130%;
}
.list-client-documents ul {
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  column-gap: .5%;
  row-gap: 5px;
  margin-bottom: 0px;
}
.list-client-documents ul li {
  padding: 16px 20px;
  max-width: 33%;
  width: 100%;
  background: var(--base-background-card);
  border-radius: 5px;
}
.list-client-documents {
  margin-top: 4px;
}
/* .list-client-documents ul li span.title {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 24px;
  line-height: 130%;
  color: #f6f6f6;
} */
/* .list-client-documents ul li > span.description {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 130%;
  color: #f6f6f6;
  margin-top: 8px;
  display: inline-flex;
} */
/* .list-client-documents ul li .links-header {
  display: flex;
  justify-content: space-between;
  column-gap: 2%;
} */
.list-client-documents ul li a {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.list-client-documents ul li a span {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 12px;
  line-height: 130%;
  color: #727272;
  margin-top: 5px;
}
.list-client-documents ul li a svg {
  width: 24px;
  height: 24px;
}
.header-bar .header-bar-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notification-layout-inner .notification-layout-sidebar-wrapper {
  max-width: 350px;
  overflow-y: auto;
  max-height: 100vh;
  position: fixed;
}
.report-details-modal {
  position: fixed;
  z-index: 22;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.tab-dropdown-mobile button,
.month-dropdown-mobile button {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #404040;
  border-radius: 3px;
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12.5px 16px;
}
.report-details-modal .report-details-modal-inner {
  min-height: 100%;
  background-color: #0000004f;
}
.report-details-modal .report-details-modal-content-wrapper {
  background: #121212;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 20px;
  min-height: 100vh;
  max-width: 100%;
  margin: 0px auto;
  width: 100%;
}
.report-details-modal-content-wrapper .selected-test-content {
  border-left: none;
}
.report-details-modal div.close-modal {
  width: 100%;
  position: relative;
}
.report-details-modal .close-modal > button {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  border: none;
  outline: 0;
}
.report-details-modal .close-modal > button svg {
  height: 40px;
  width: 40px;
}
span.test-proposition {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
.report-details-modal .close-modal > button svg path {
  fill: #fff;
}
.report-details-modal .selected-test-content {
  /* margin-left: 0px; */
  padding-left: 0px;
}
.analytics-card-figures .analytics-amount {
  color: var(--base-font-color);
  font-weight: var(--base-font-weight);
  font-size: 21px;
  line-height: 24px;
  text-transform: capitalize;
}
.analytics-percentage {
  color: var(--base-font-color);
  border-radius: 3px;
  padding: 4px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: normal;
  max-width: fit-content;
  /* margin: 3px 0px; */
}
.analytics-percentage > svg {
  margin-right: 1px;
}
.analytics-percentage span:not(:first-child),
.analytics-percentage span.add-rev-amt {
  font-weight: 500;
  font-size: 13px;
  white-space: nowrap;
}

.analytics-increment {
  background-color: #1bc44a;
}
.analytics-decrement {
  background-color: #b03232;
}

/* analytics ends  */
/* graph section starts  */

.client-average-revenue {
  width: 100%;
  max-width: 47.4%;
}
.average-revenue-title {
  font-weight: var(--base-font-weight);
  font-size: var(--heading-size);
  line-height: var(--heading-line-height);
  text-transform: capitalize;
  color: var(--base-font-color);
  /* margin-bottom: px; */
  margin: 0px 0px 16px 0px;
  /* max-inline-size: 55%; */
}
.report-charts-height > canvas {
  max-height: 300px;
}
.average-revenue-date-selector {
  position: relative;
  display: flex;
  column-gap: 5px;
}
/* react select style starts  */

:is(.client-details) .acc-newselect__single-value,
.acc-newselect__input {
  color: #b9b9b9;
}

:is(.client-details) .fields-wrapper .acc-newselect__placeholder {
  color: #727272 !important;
}

.client-details .acc-newselect__menu-list .acc-newselect__option {
  line-height: 26px;
}
.client-details
  .acc-newselect__menu-list
  .acc-newselect__option.acc-newselect__option--is-disabled {
  opacity: 0.1;
}
.acc-newselect__menu-list .acc-newselect__option--is-selected::after {
  content: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.45455 8L0 4.36364L3.45455 6.18182L10.5455 0L3.45455 8Z' fill='%23F6F6F6'/%3E%3C/svg%3E%0A");
  position: absolute;
  margin-right: 10px;
  right: 0;
}

:is(.client-details) .acc-newselect__option--is-selected::after {
  content: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.45455 8L0 4.36364L3.45455 6.18182L10.5455 0L3.45455 8Z' fill='%23F6F6F6'/%3E%3C/svg%3E%0A");
}

.acc-newselect__single-value,
.acc-newselect__menu,
.acc-newselect__value-container--has-value,
.acc-newselect__placeholder,
.acc-newselect__value-container {
  transition: 0.3s all ease-in-out;
}
:is(.client-details)
  :is(.acc-newselect__control--menu-is-open
    .acc-newselect__single-value, .acc-newselect__control--menu-is-open
    .acc-newselect__placeholder) {
  padding-left: 20px;
}

.acc-newselect__indicator-separator {
  display: none;
}

.acc-newselectwrapper .acc-newselect__control {
  background-color: transparent;
  border: 1px solid #404040;
  border-radius: 3px;
}
.experiment-heading-divider .showaovrpucta {
  border: 1px solid #727272;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 18px;
  text-decoration: none;
  font-size: 15px;
  color: var(--base-white-color);
}
.experiment-dropdown-text .acc-newselectwrapper .acc-newselect__control {
  border: 1px solid transparent;
}

:is(.client-dropdown, .properties-dropdown)
  :is(.acc-newselect__value-container) {
  padding-left: 0px;
}

.dark-calendar .rdrDefinedRangesWrapper {
  background-color: var(--base-background-card);
  display: none;
}

.client-dropdown .acc-newselectwrapper .acc-newselect__control,
.properties-dropdown .acc-newselectwrapper .acc-newselect__control {
  border: none;
  border: none;
  background: linear-gradient(
      to right,
      #fff 0%,
      #fff 12%,
      #fff 47%,
      rgb(83 0 30 / 0%) 100%
    )
    left bottom #7770 no-repeat;
  background-size: 100% 1px;
}
.acc-newselectwrapper:focus-visible {
  outline: 0;
}
.tests-canbe-evaluated span {
  display: inline-flex;
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 22px;
  line-height: 130%;
  color: #f6f6f6;
}
.tests-canbe-evaluated {
  margin: 14px 0px 30px;
}
.acc-newselect__indicators > .acc-newselect__indicator:last-child {
  height: 100%;
  padding: 0;
  width: 36px;
  align-items: center;
  justify-content: center;
  background-image: url("/public/Images/selectarrow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}
:is(.acc-newselect__value-container) .acc-newselect__single-value,
.acc-newselect__placeholder,
.acc-newselect__input {
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 150%;
  text-transform: capitalize;
  white-space: nowrap;
  color: var(--base-white-color) !important;
}
.showhidesignificance-cta button.btn-as-link {
  background-color: #003ed4;
  font-weight: var(--base-font-weight);
  cursor: pointer;
  font-size: 14px;
  color: white;
  text-decoration: none;
}
.downloadcsvbutton {
  margin: 20px 0px;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #003ed4;
  width: fit-content;
}
.downloadcsvbutton a {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 13px;
  text-decoration: none;
}
.acc-newselect__indicator > svg {
  display: none;
}

.acc-newselectwrapper .acc-newselect__menu {
  font-size: 14px;
  min-width: 105px;
  box-shadow: 0px 4px 6px rgba(11, 11, 11, 0.5);
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
  transition: all 0.3s ease-in-out;
  z-index: 5;
  background-color: #222222;
  border: 0.5px solid #727272;

}
.acc-newselect__control--menu-is-open {
  transition: 0.3s all ease-in-out;
}

.acc-newselect__control--menu-is-open .acc-newselect__input-container {
  padding-left: 20px;
}
/* .acc-newselect__control--is-focused .acc-newselect__input-container{
  padding-left: 20px;
} */
.acc-newselect__dropdown-indicator {
  transition: 0.3s all ease-in-out !important;
}
.acc-newselectwrapper:has(.acc-newselect__control--menu-is-open)
  .acc-newselect__dropdown-indicator {
  transform: rotate(180deg);
}

.acc-newselect__menu .acc-newselect__menu-list {
  padding: 0;
  border-radius: 5px;
  width: 100%;
}
.acc-newselect__menu .acc-newselect__menu-list::-webkit-scrollbar-track {
  background-color: #404040;
  border-radius: 5px;
}

.acc-newselect__menu .acc-newselect__menu-list::-webkit-scrollbar {
  width: 5px;
}
.acc-newselect__menu .acc-newselect__menu-list::-webkit-scrollbar-thumb {
  background-color: #727272;
  border-radius: 5px;
}
.acc-newselect__menu-list .acc-newselect__option {
  background-color: var(--base-background-card);
  border-radius: 3px;
  color: #fff;
  text-align: left;
  cursor: pointer;
  line-height: 22px;
  text-transform: capitalize;
  padding: 10px;
  font-size: 13px;

  /* margin: 4px 0px; */
}
.acc-newselect__option.acc-newselect__option--is-focused {
  background-color: #404040;
}

/* react select style ends  */
.average-revenue-date-selector .acc-newselectwrapper {
  max-width: 145px;
  width: 100%;
}
.strategy ul.splide__pagination {
  position: initial;
  margin-top: 5px;
  padding: 0px 0px;
}
.average-revenue-date-selector > span {
  max-width: 100%;
  width: 100%;
  display: inline-flex;
}
.average-revenue-date-selector > span > button {
  width: 100%;
  border: 1px solid #404040 !important;
  border-radius: 3px;
  color: var(--base-font-color);
  padding: 7px 10px;
}
.average-revenue-date-selector > span > button > svg {
  margin-right: 10px;
}
.average-revenue-date-selector > span > button:hover {
  color: var(--base-font-color);
}
.graph img {
  max-width: 100%;
}
/*  graph section ends  */

/*  client stats ends */

/* Stratergy and Roadmap starts  */
section.strategy {
  padding: var(--section-padding);
}
.strategy-title {
  font-weight: var(--base-font-weight);
  font-size: var(--heading-size);
  line-height: var(--heading-line-height);
  color: var(--base-font-color);
  text-transform: capitalize;
}
.strategy-inner .current-status {
  display: flex;
  flex-direction: column;
}
.strategy-sub-title {
  font-weight: var(--base-font-weight);
  font-size: 21px;
  line-height: 24px;
  color: #b9b9b9;
  margin: 16px 0px;
}

.stratergy-months-card-list {
  display: flex;
  gap: 0.43%;
  position: relative;
  /* justify-content: space-between; */
}

.month-card {
  padding: 24px 20px;
  border: 1px solid #404040;
  border-radius: 5px;
  flex: 1 1;
  /* max-width: 32.9%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.date-select-range-wrapper .graph {
  margin-top: 12px;
}
.month-card-content .month-name,
.month-modal .month-name {
  font-weight: var(--base-font-weight);
  font-size: 26px;
  color: var(--base-font-color);
  line-height: 29px;
}
.month-card .monthly-test-list {
  list-style-type: none;
  padding-left: 0px;
  margin: 20px 0px 0px;
}
.monthly-test-list .test-detail {
  display: flex;
  align-items: flex-start;
  line-height: 23px;
}
.monthly-test-list .test-detail:not(:last-child) {
  margin-bottom: 12px;
}

.icon {
  width: 20px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
/* .test-detail .icon {
    margin-right: 20px;
  } */
.monthly-test-list .task-status {
  font-weight: var(--base-font-weight);
  font-size: 15px;
  /* line-height: 23px; */
  margin-bottom: 0px;
  /* text-transform: capitalize; */
  color: var(--base-font-color);
}
.button {
  background-color: #404040;
  border-radius: 3px;
  text-decoration: none;
  color: #fff;
  display: block;
  padding: 8px;
  text-align: center;
}
.month-card .month-detail-btn.month-active {
  background-color: var(--primary-color);
}
.month-detail-btn {
  text-transform: uppercase;
  text-decoration: none;
  color: var(--base-font-color);
}
.month-detail-btn:hover {
  opacity: 0.9;
  color: var(--base-font-color);
}
/* model open close css */

.month-modal {
  /* position: absolute; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
  transition: 0.4s all ease-in-out;
  /* padding: 24px 20px; */
  /* border: 1px solid #404040; */
  /* border-radius: 5px; */
  /* max-width: 32.5%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.learnings-report > h3 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
  line-height: var(--heading-line-height);
  margin-bottom: 0px;
  padding: 0px 0px;
}
.learnings-report ul {
  padding-left: 10px;
  margin-bottom: 0px;
  list-style: none;
}
.learnings-report ul > li span {
  color: #fff;
}
.card-modal-hidden {
  opacity: 0;
  display: none;
}

/* Stratergy and roadmap ends  */

/* learning -section starts  */

section.top-learning,
section.tests-overview {
  padding: var(--section-padding);
}
.top-learning-inner {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.learning-list {
  width: 100%;
  max-width: 49.8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calculated-kpis-variants {
  padding: 16px 0px 16px;
}
.product-page .calculated-kpis-variants,
.product-page .selected-test-content .graph-component {
  padding: 0px;
  margin-top: 50px;
}
.product-page .graph-component > .section-heading-data {
  margin-bottom: 20px;
  display: none;
}
.product-page .graph-component > .reporting-subtitle {
  display: none;
}
.calculated-kpis-variants.advanced-details {
  padding: 0px 0px;
}

.learning-list-content {
  background: var(--base-background-card);
  border-radius: 5px;
  padding: 16px 20px;
  height: 100%;
}
.learning-list-content ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.learning-list-content ul li {
  list-style-type: none;
  display: flex;
  align-items: center;
}
.learning-list-content li:not(:last-child) {
  margin-bottom: 24px;
}
.test-details-table-data.snippets-details
  .learning-list-content
  li:not(:last-child) {
  margin-bottom: 4px;
}

.list-client-documents {
  margin-top: 4px;
}
.list-client-documents ul li span.title {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 24px;
  line-height: 130%;
  color: #f6f6f6;
}
.list-client-documents ul li > span.description {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 130%;
  color: #f6f6f6;
  margin-top: 8px;
  display: inline-flex;
}
.list-client-documents ul li .links-header {
  display: flex;
  justify-content: space-between;
  column-gap: 2%;
}
.list-client-documents ul li a {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.data-store-tabs {
  display: inline-flex;
  align-items: center;
  justify-content: normal;
  margin-bottom: 30px;
  border-radius: 4px;
  overflow: hidden;
}
.data-store-tabs > button {
  padding: 10px 15px;
  border: none;
  box-shadow: none;
  text-align: center;
  min-width: 250px;
}
.data-store-tabs > button.active {
  background-color: #003ed4;
  color: #fff;
}
.list-client-documents ul li a span {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 12px;
  line-height: 130%;
  color: #727272;
  margin-top: 8px;
}
.list-client-documents ul li a svg {
  width: 24px;
  height: 24px;
}
.learning-list-content .learning-text {
  font-weight: var(--base-font-weight);
  font-size: 15px;
  color: #ffffff;
  line-height: 20px;
  margin-bottom: 0px;
  display: inline-block;
  max-width: 100%;
    width: 100%;
}
.next-steps {
  width: 100%;
  max-width: 49.8%;
}
.next-steps-heading {
  font-weight: var(--base-font-weight);
  font-size: var(--heading-size);
  line-height: var(--heading-line-height);
  text-transform: capitalize;
  color: var(--base-font-color);
  margin-bottom: 20px;
}
.steps-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  row-gap: 10px;
  flex-wrap: wrap;
}
.steps-wrapper .current-step:not(:last-child) {
  max-width: 49%;
  width: 100%;
}
.steps-wrapper .current-step:last-child {
  max-width: 100%;
  width: 100%;
}
.steps-group {
  display: flex;
  gap: 10px;
}
/* .steps-group > .current-step:first-child{
    min-height: 93px;
  } */
.top-learning .current-step {
  padding: 16px 20px;
  background-color: var(--base-background-card);
  border-radius: 5px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.tests-overview .current-step {
  padding: 16px 20px;
  border: 1px solid #404040;
  border-radius: 3px;
  padding: 12px 16px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}
.tests-overview .current-step:not(:last-child) {
  max-width: 32.5%;
}
.tests-overview .current-step h3 {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: var(--base-font-color);
  margin-bottom: 12px;
}
.tests-overview .current-step p {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 21px;
  line-height: 24px;
  text-transform: capitalize;
  color: var(--base-font-color);
  display: inline-flex;
  gap: 12px;
  align-items: center;
}
.tests-overview .test-overview-box h3 {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: var(--base-font-color);
  margin-bottom: 8test-overview-boxpx;
}
.tests-overview .test-overview-box h4 {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 30px;
  line-height: 34px;
  text-transform: capitalize;
  color: var(--base-font-color);
  margin: 8px 0px 24px;
}
.tests-overview .test-overview-box p {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 20px;
  color: var(--base-font-color);
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  gap: 13px;
}
.tests-overview .learning-list {
  border: 1px solid #404040;
  border-radius: 3px;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
}
section.tests-overview .learning-list {
  justify-content: initial;
}
.current-step p {
  margin-bottom: 0px;
  color: var(--base-font-color);
  font-size: 15px;
  font-weight: var(--base-font-weight);
  line-height: 20px;
}
/* learning -sction ends  */

/* AB Testing Dashboard start */
/* .ab-test-overview {
  margin: 20px 0;
}
.experiment-list-heading {
  font-weight: var(--base-font-weight);
  font-size: var(--heading-size);
  line-height: var(--heading-line-height);
  margin-bottom: 20px;
  color: var(--base-font-color);
}
.experiment-list-inner { 
  display: flex;
  gap: 10px;
} */
/* .experiment-box {
  width: 100%;
  border: 1px solid #404040;
  border-radius: 10px;
  overflow: hidden;
}
.experiment-box-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #404040;
  padding: 15px 20px;
  background-color: var(--base-background-card);
}
.experiment-box-head h3 {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: var(--base-font-color);
  margin-bottom: 0px;
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
}
.experiment-box-head h3 span{
  background: #fff;
  color: #000;
  font-size: 12px;
  padding: 3px 12px;
  border-radius: 3px;
} */
/* .experiment-box-head h3 span.preview{
  background-color: orange;
}
.experiment-box-head h3 span.live{
  background-color: #1bc44a;
  color: #fff;
} */

/* .experiment-box-head p {
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 20px;
  color: var(--base-font-color);
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  gap: 13px;
}
.experiment-box-sub-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #404040;
  padding: 12px 20px;
  background-color: var(--base-background-card);
}
.experiment-box-body {
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #404040;
}
.experiment-box-body .fields-wrapper label {
  position: initial;
  top: 0;
  left: 0;
  background-color: transparent;
  margin-bottom: 8px;
  color: #ebebeb;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.experiment-box-body .fields-wrapper {
  padding: 6px 15px;
}
.experiment-box-body .fields-wrapper textarea {
  padding: 6px 15px;
  border: none;
  background-color: #404040;
  border-radius: 5px;
  font-size: 14px;
  color: #f6f6f6;
  width: 100%;
  min-height: 92px;
}

.experiment-box-body .fields-wrapper textarea:focus-visible {
  outline: none;
}
.experiment-box-body-head {
  display: flex;
  align-items: center;
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 20px;
  color: var(--base-font-color);
  padding: 12px 20px;
}
.experiment-box-body-inner {
  width: 100%;
  padding: 12px 20px;
  border-bottom: 1px solid #404040;
} */
/* .body-inner-url-conatiner {
  display: flex;
  width: 100%;
  gap: 5px;
  padding: 5px 0px;
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 20px;
  color: var(--base-font-color);
}
.body-inner-url-conatiner input {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  outline: 0;
  border: 1px solid #dfe2e8;
  box-sizing: border-box;
  padding: 24px 15px;
  border: none;
  background-color: #404040;
  border-radius: 10px;
  font-weight: 300;
  color: var(--base-font-color);
} */
/* .remove-data-icon {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.remove-data-icon svg {
  width: 30px;
  height: 30px;
  fill: #ebebeb !important;
} */
/* .experiment-box-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 20px;
  align-items: center;
  color: var(--base-font-color);
  padding: 12px 20px;
  background-color: #222222;
} */
/* AB Testing Dashboard ends */

/* PRODUCTS CARD SECTIONS */
section.product-page {
  padding: var(--section-padding);
}
.tab-component {
  border-bottom: 0.5px solid var(--base-white-color);
  display: flex;
  gap: 5px;
}
.tab-component .tab {
  display: inline-flex;
  align-items: center;
  padding: 16px 20px;
  border-radius: 5px 5px 0px 0px;
  background-color: var(--base-background-card);
  min-width: 23.95%;
  cursor: pointer;
  /* width: 100%; */
}
.tab-component .tab-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #b9b9b9;
}
.tab-component .tab-active {
  background-color: var(--base-white-color);
}
.tab-component .tab-active .tab-title {
  color: var(--base-background-card);
}
.product-page-search {
  display: flex;
  padding: 20px 0px 5px;
  column-gap: 10px;
  border-bottom: 0.5px solid #b9b9b9;
  margin-bottom: 10px;
}
/* .product-page .product-page-search {
  padding: 30px 0px;
} */
.product-page-search > .search-wrapper {
  width: 100%;
  max-width: 100%;
  /* margin-right: 15px; */
  /* flex-shrink: 0; */
  display: flex;
  position: relative;
  padding-bottom: 8px;
  align-items: flex-end;
  column-gap: 5px;
}
.search-wrapper .search-icon {
  max-width: 18px;
}
.faq-search .search-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.search-wrapper .search-icon svg {
  max-width: 100%;
  width: 100%;
}
.faq-search .search-icon svg {
  width: 24px;
  height: 24px;
}
.input-search .product-search {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: "Neue Haas Grotesk Text Pro", "sans-serif";
  width: 100%;
  font-size: 18px;
  /* line-height: 24px; */
  /* padding-left: 18px; */
  color: var(--base-white-color);
  height: 100%;
}
.input-search .product-search::placeholder {
  color: #727272;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: var(--base-font-weight);
  line-height: 21px;
}
/* .product-card-view .grid-view,
.product-card-view .menu-view {
  padding: 8px;
} */
.sort-by span {
  font-size: 15px;
  line-height: 20px;
  font-weight: var(--base-font-weight);
  color: var(--base-white-color);
}
.product-card-view .sort-icon {
  margin-left: 16px;
}
.product-page-title {
  font-size: 26px;
  font-weight: var(--base-font-weight);
  line-height: 29px;
  color: var(--secondary-white-color);
  margin: 25px 0px;
}
.product-page-card-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* gap: 20px 0.33%; */
  row-gap: 10px;
}
.product-page-card-list.product-card-list-view {
  gap: 0px;
}
.product-page-card {
  background: var(--base-background-card);
  border-radius: 5px;
  padding: 13px 20px;
  width: 100%;
  max-width: 32.8%;
  display: inherit;
  justify-content: space-between;
  flex-direction: column;
}
.product-page-card > .content{
  position: relative;
  overflow: hidden;
}

.product-page-card > .content:hover .page-card-img-hover , .product-page-card > .content:hover .page-card-img{
  transform: translate(0px, -100%);
}
.product-page-card .page-card-title {
  font-size: 16px;
  font-weight: var(--base-font-weight);
  line-height: 22px;
  text-align: center;
  width: 100%;
  word-break: break-all;
  padding-bottom: 13px;
  color: var(--base-font-color);
  border-bottom: 1px solid #727272;
}
.page-card-img {
  padding: 12px 0px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.page-card-footer {
  border-top: 0.5px solid #b9b9b9;
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
}
.page-card-footer::-webkit-scrollbar {
  border-radius: 10px;
  height: 4px;
}
.page-card-footer::-webkit-scrollbar-thumb {
  background-color: #727272;
  width: 4px;
  border-radius: 5px;
}
.page-card-footer::-webkit-scrollbar-track {
  background-color: #404040;
  border-radius: 5px;
}
.updated-page-card-footer {
  column-gap: 20px;
  overflow-x: auto;
  margin-bottom: 5px;
}
.updated-page-card-footer > .test-results {
  align-items: center;
}
.updated-page-card-footer .test-results .footer-title.footer-data.result {
  text-align: center;
}
.updated-page-card-footer
  .test-results
  .footer-title.footer-data.additional-revenue {
  text-align: center;
  margin-bottom: 10px;
}
.updated-footer-title {
  white-space: nowrap;
}
.page-card-footer .test-details,
.page-card-footer .test-results {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  row-gap: 2px;
}
.page-card-footer .test-results .analytics {
  display: inline-flex;
  column-gap: 5px;
}
.test-details .footer-title,
.test-details .footer-title-data,
.test-results .footer-title,
.test-results .footer-title-data {
  font-size: 14px;
  font-weight: var(--base-font-weight);
  line-height: 15px;
  color: #b9b9b9;
}
.test-details .footer-title-data,
.test-results .footer-title-data {
  color: var(--base-white-color);
  text-transform: capitalize;
}
.test-details .footer-data:nth-child(2) {
  margin-bottom: 8px;
}
.page-card-footer .footer-data:nth-child(odd) {
  margin-bottom: 4px;
}
.test-results .result-group {
  display: flex;
  flex-direction: column;
}
.product-card-btn {
  font-size: 15px;
  font-weight: var(--base-font-weight);
  line-height: 20px;
  color: var(--base-white-color);
}
.product-card-btn:hover {
  background-color: var(--primary-color);
  color: var(--base-font-color);
}
.product-card-btn.disabled {
  background-color: grey;
  cursor: not-allowed;
}
.page-card-img-hover {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  position: absolute;
  cursor: pointer;
  transform: translate(0px, -0px);
  min-height: 400px;
  transition: 0.3s all ease-in-out;
  left: 0;
  right: 0;
}
.d-none {
  display: none;
}
.hover-test-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 49%;
}
.hover-test-data .test-result-heading {
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--base-font-weight);
  color: #b9b9b9;
  margin-bottom: 12px;
}
.hover-test-data img {
  width: 100%;
  height: 100%;
  max-height: 346px;
  object-fit: contain;
  object-position: top;
  /* background: #fff; */
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
  border-radius: 3px;
  background-size: 10px 
}
/*  */

/* PRODUCT CARD LIST VIEW */
.product-page-card-list .list-view-headings {
  display: none;
}
.product-card-list-view {
  flex-direction: column;
}
.product-card-list-view .list-view-headings {
  display: flex;
  width: 100%;
  margin-top: 40px;
  align-items: center;
  padding: 10px 12px;
  padding-top: 0px;
  border-bottom: 1px solid var(--base-white-color);
  column-gap: 20px;
}
#reports-ended-test:has(.product-card-list-view) .product-page-title {
  display: none;
}
.list-view-headings .list-view-heading-single {
  text-transform: capitalize;
  font-weight: var(--base-font-weight);
  font-size: 18px;
  line-height: 20px;
  color: #727272;
  margin-bottom: 0px;
}
.product-card-list-view .product-page-card {
  max-width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0px;
  justify-content: space-between;
  padding: 35px 12px;
  background-color: transparent;
  flex-direction: row;
  column-gap: 20px;
}
.product-card-list-view .product-page-card .footer-title-data,
.product-card-list-view .product-page-card .footer-data.test-dev-ops,
.product-card-list-view .product-page-card .footer-data.days,
.product-card-list-view .product-page-card .analytics,
.product-card-list-view .product-page-card .product-card-btn,
.product-card-list-view .list-view-heading-single {
  width: 100%;
  max-width: calc(100% / 6);
}
/* .product-card-list-view .page-card-title, .product-card-list-view .list-view-headings .list-view-heading-single:first-child {
  flex-basis: 9.4%;
} */

.product-card-list-view .list-view-heading-single {
  width: 100%;
}

.product-card-list-view .product-page-card:nth-child(odd) {
  background-color: var(--base-background-card);
}
.product-card-list-view .page-card-title {
  border: none;
  font-size: 16px;
  padding: 0px;
  text-align: initial;
}
.product-card-list-view
  :is(.content, .tested-by, .duration, .result, .footer-title.additional-revenue) {
  display: none;
}
.product-card-list-view .page-card-footer {
  border: none;
  /* width: 100%; */
  display: initial;
  padding: 0px;
}
.product-card-list-view
  :is(.test-details, .test-results, .result-group, .page-card-footer) {
  display: contents;
}

.product-card-list-view .test-dev-ops {
  order: 2;
  margin-bottom: 0px !important;
}

.product-card-list-view .days {
  order: 3;
}

.product-card-list-view .result-data {
  order: 1;
  font-size: 16px;
  text-transform: capitalize;
}
.product-card-list-view .result-data.success {
  color: var(--success-color);
}
.product-card-list-view .result-data.nouplift {
  color: var(--danger-color);
}

.product-card-list-view .analytics {
  order: 4;
}
.product-card-list-view .analytics-percentage {
  display: inline-flex;
}
.product-card-list-view .analytics-percentage .figure {
  font-size: 12px;
}
.product-card-list-view .product-card-btn {
  padding: 8px 0px;
  order: 5;
  font-size: 14px;
}
/* .product-card-list-view .product-card-btn,.product-card-list-view .list-view-heading-single:last-of-type {
  max-width: 142px;
} */
.product-card-list-view .list-view-heading-single.duration-title,
.product-card-list-view .list-view-heading-single.percentage,
.product-card-list-view .list-view-headings.list-view-heading-single:last-child,
.product-card-list-view .days,
.product-card-list-view .page-card-footer .test-results .analytics,
.product-card-list-view .list-view-heading-single.additional-revenue {
  text-align: center;
  justify-content: center;
}
.product-card-list-view .product-page-card > div:first-child,
.product-card-list-view .list-view-heading-single:first-of-type {
  min-width: 15%;
}
/* PRODICT CARD LIST VIEW ENDS */

/* REPORTING CSS */

.divider {
  display: flex;
}
.sidebar .sidebar-text {
  display: none;
}

.sidebar-is-open .sidebar .sidebar-text {
  display: block;
}
.main-content {
  width: 100%;
  max-width: calc(100vw - 110px);
}
.main-content-inner {
  padding: 72px 100px;
}
.sidebar-is-open .sidebar + .main-content .main-content-inner {
  padding: 85px 50px;
}
.sidebar-is-open .sidebar + .main-content .analytics-card {
  max-width: 47%;
}

/* live reporting starts  */
.live-icon {
  display: flex;
  margin-right: 15px;
}

.live-icon svg {
  animation: glowing 1s ease-in-out infinite alternate;
  border-radius: 50%;
}
.report-head-main {
  display: flex;
  align-items: center;
  /* margin-bottom: 30px; */
}
/* .report-head-main .section-heading-title {
  font-size: 40px;
  line-height: 40px;
  text-transform: uppercase;
  color: var(--base-white-color);
  margin: 0;
  font-weight: var(--base-font-weight);
} */
.section-heading,
.calculated-kpis-variants .variant-name {
  font-size: var(--heading-size);
  font-weight: var(--base-font-weight);
  text-transform: capitalize;
  line-height: var(--heading-line-height);
  color: var(--secondary-white-color);
  margin-bottom: 0px;
}
.client-dropdown .section-heading,
.properties-dropdown .section-heading {
  margin-bottom: 17px;
}
.client-details {
  width: 100%;
  max-width: 47.4%;
}
.client-details.full{
  max-width: 100%;
}
.client-details.full > .client-dropdown:not(.full){
  max-width: 49%;
}
.client-details.hideclientselector > .client-dropdown{
  display: none;
}
.experiment-details {
  width: 100%;
  max-width: 49%;
}
.experiment-details .section-heading {
  margin-bottom: 20px;
}
.client-data-wrapper > div{
  flex: 1 1 0;
}
.client-data-wrapper .client-dropdown {
  margin-bottom: 20px;
}
.client-data-wrapper .client-dropdown:last-child {
  margin-bottom: 0px;
}
.client-data-wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  flex-wrap: wrap;
  row-gap: 20px;
}
client-data-wrapper .experiment-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 2%;
  row-gap: 10px;
}
.experiment-name {
  background-color: var(--base-background-card);
  border-radius: 5px;
  padding: 25px 5px;
  overflow-wrap: anywhere;
  width: 100%;
  /* max-width: 32%; */
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.experiment-name p {
  margin-bottom: 0px;
  color: var(--base-font-color);
}
.data-sets-loaded .experiment-name p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.experiment-active {
  background-color: var(--primary-color);
}
.experiment-active-icon {
  display: inline-flex;
}

/* live reporting ends  */

/* CVR Report starts */
.cvr-report-wrapper {
  display: flex;
  justify-content: center;
  color: #fff;
}
.cvr-title-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.close-client {
  width: 20px;
  margin-bottom: 5px;
  border: 0;
  background: transparent;
}
/* CVR Report ends */

/* experiment-data-starts  */
section.experiment-data {
  margin-top: var(--reporting-section-gap);
}
section.experiment-data .experiment-data-inner {
  padding: 25px 0px;
}
.experiment-heading-divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  flex-wrap: wrap;
}
.selected-test-content-title,
.selected-test-content-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selected-test-content .selected-comparison-head {
  text-decoration: underline;
  text-decoration-color: var(--base-white-color);
  text-decoration-thickness: 1px;
  background-color: var(--primary-color);
  color: var(--base-font-color);
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 0px;
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 0px;
  font-weight: var(--base-font-weight);
}
.section-heading-data {
  display: flex;
  align-items: flex-end;
}
.section-heading-subtitle {
  font-size: 21px;
  font-weight: var(--base-font-weight);
  line-height: 30px;
  color: #b9b9b9;
  margin-left: 20px;
  align-self: flex-end;
}

.ab-compare {
  display: flex;
  align-items: center;
  padding: 8px 25px;
  background-color: var(--base-background-card);
  border-radius: 5px;
  gap: 5px;
  color: var(--base-font-color);
  cursor: pointer;
}
.ab-compare > span {
  font-size: 15px;
  font-weight: var(--base-font-weight);
  line-height: 130%;
}
.datepicker-wrapper.dark-calendar-wrapper {
  left: 0;
  right: auto;
  top: 40px;
  background-color: var(--base-background-card);
  border: 0.5px solid #727272;
  box-shadow: 0px 4px 6px rgba(11, 11, 11, 0.5);
  border-radius: 5px;
  /* width: 100%; */
  /* max-width: 100%; */
}
/* .rdrDateRangePickerWrapper {
  width: 100%;
  max-width: 100%;
} */
.date-selector .datepicker-wrapper.dark-calendar-wrapper {
  left: auto;
  right: 0;
  top: 4px;
}
.date-selector .dark-calendar .datepicker-wrapper {
  background-color: var(--base-background-card);
  /* djhdm */
}
.date-selector button {
  color: #fff;
  padding: 0;
}
.date-selector button > svg {
  margin: 0px 10px;
}
.date-selector {
  max-width: fit-content;
  width: 100%;
  text-align: center;
  padding: 7px 0px;
}
.date-selector button:hover {
  color: var(--color-neon);
}
.dark-calendar .rdrCalendarWrapper {
  background-color: var(--base-background-card);
  /* width: 100%; */
  /* max-width: 100%; */
}
.dark-calendar .rdrDayNumber span {
  color: #fff;
}
.dark-calendar .rdrDayDisabled {
  background-color: transparent !important;
}
.dark-calendar .rdrSelected,
.dark-calendar .rdrInRange,
.dark-calendar .rdrStartEdge,
.dark-calendar .rdrEndEdge {
  color: rgba(61, 97, 185, 0.5) !important;
}
.dark-calendar .rdrStartEdge,
.dark-calendar .rdrEndEdge {
  color: var(--primary-color) !important;
  border-radius: 5px;
}
.dark-calendar .rdrStartEdge {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.dark-calendar .rdrEndEdge {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.dark-calendar span.rdrStartEdge.rdrEndEdge {
  border-radius: 5px;
}
.rdrPprevButton i,
.rdrNextButton i {
  display: none;
}
.rdrNextPrevButton {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: 1px solid #b9b9b9;
}
.rdrPprevButton,
.rdrPprevButton:hover {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='5' height='10' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.54964e-07 4.75L5 9.5L2.5 4.75L5 0L2.54964e-07 4.75Z' fill='%23F1F1F1'/%3E%3C/svg%3E%0A");
}
.rdrNextPrevButton:hover {
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  box-shadow: rgba(100, 100, 111, 0.7) 0px 0px 8px 0px;
}

.rdrNextButton,
.rdrNextButton:hover {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='5' height='10' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 4.75L0 9.5L2.5 4.75L-5.09928e-07 0L5 4.75Z' fill='%23F1F1F1'/%3E%3C/svg%3E%0A");
}

.dark-calendar .rdrDateDisplayWrapper {
  background-color: var(--base-background-card);
  display: none;
}
.dark-calendar .rdrMonthName {
  color: #fff;
  font-weight: var(--base-font-weight);
  text-align: center;
  font-size: 12px;
  line-height: 150%;
  padding: 5px;
}
.dark-calendar .rdrMonthAndYearPickers select {
  color: #fff;
  padding: 0;
}
.dark-calendar .rdrMonthAndYearPickers select::-webkit-scrollbar {
  border-radius: 10px;
  width: 4px;
}
.dark-calendar .rdrMonthAndYearPickers select::-webkit-scrollbar-thumb {
  background-color: #727272;
  width: 4px;
  border-radius: 5px;
}
.dark-calendar .rdrMonthAndYearPickers select::-webkit-scrollbar-track {
  background-color: #404040;
  border-radius: 5px;
}
.rdrMonthAndYearPickers {
  justify-content: space-between;
}
.rdrMonth {
  padding-bottom: 0px;
}
.dark-calendar .rdrDayPassive .rdrDayNumber span {
  color: #727272;
}
.rdrMonthsVertical {
  justify-content: center;
  align-items: center;
}
.rdrDay {
  line-height: 2.5em;
  height: 2.5em;
}
.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  top: 3px;
  bottom: 3px;
}
.rdrMonthAndYearWrapper {
  height: 50px;
}

.dark-calendar .rdrMonthAndYearPickers select option {
  background-color: var(--base-background-card);
}
.datepicker-wrapper .buttons-operations {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: flex-end;
  padding: 6px 6px;
  margin-top: 9px;
}
.experiment-data-navbar {
  margin-top: 26px;
  border-top: 1px solid #404040;
  border-bottom: 1px solid #404040;
  display: flex;
  /* align-items: center; */
  position: relative;
  /* padding: 15px 0px; */
}
.experiment-data-navbar.history-tabs {
  column-gap: 2%;
  row-gap: 10px;
  flex-wrap: wrap;
  border: none;
  margin-top: 24px;
  padding: 0px 0px;
}
.experiment-data-navbar.history-tabs > div {
  color: var(--color-neon);
  border: none;
  cursor: auto;
  width: 100%;
  /* max-width: 358px; */
  /* padding: 8px 12px; */
  /* border: 2px solid transparent; */
  /* border-radius: 5px; */
  /* background-color: #222222; */
}
.experiment-data-navbar.history-tabs ul.splide__list li {
  background: var(--base-background-card);
  border-radius: 5px;
}
.splide ul.splide__pagination {
  position: initial;
  margin-top: 2px;
}
.splide .splide__pagination__page {
  border: 1px solid #ebebeb !important;
  background-color: transparent !important;
}
.splide .splide__pagination__page.is-active {
  background: #ebebeb !important;
  transform: scale(1) !important;
}
/* .experiment-data-navbar.history-tabs div{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 32%;
} */
.experiment-data-navbar.history-tabs div .rp-context {
  display: flex;
  column-gap: 12px;
}
.experiment-data-navbar.history-tabs div .rp-context .remove-hitsory-tab {
  max-width: 10px;
  width: 100%;
  cursor: pointer;
}

.remove-hitsory-tab-new {
  position: absolute;
  right: 5px;
  top: 5px;
}
.experiment-data-navbar.history-tabs .splide__slide > div.currentactivehistory {
  border-color: var(--color-neon);
}
.experiment-data-navbar.history-tabs
  div
  span.rp-context
  span:not(.history-date) {
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 150%;
  overflow: hidden;
  overflow-wrap: anywhere;
  color: #fff;
  display: inline-flex;
  padding-bottom: 8px;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  text-transform: uppercase;
}

.segment-names-capitalised {
  text-transform: capitalize;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 10px;
  width: fit-content;
  flex-shrink: 0;
  min-width: 25%;
}
.segment-names-capitalised:not(:last-child)::after {
  content: "";
  color: #727272;
  height: 100%;
  position: absolute;
  right: 0px;
  border: 1px solid #727272;
}
.segment-key {
  color: #b9b9b9;
  font-size: 14px;
}
.segment-value {
  color: #fff;
  font-size: 14px;
}

.history-tabs-wrapper {
  overflow-x: auto !important;
  width: 100%;
}
.history-tabs-wrapper .segment-names-capitalised:not(:first-child) {
  padding-left: 10px;
}
.history-tabs-wrapper::-webkit-scrollbar-track {
  background-color: #404040;
  border-radius: 5px;
}
.history-tabs-wrapper::-webkit-scrollbar {
  height: 4px;
  background-color: #404040;
  border-radius: 5px;
}
.history-tabs-wrapper::-webkit-scrollbar-thumb {
  background-color: #727272;
  border-radius: 5px;
}
.experiment-data-navbar.history-tabs
  div
  span.rp-context
  span:not(.history-date).segment-names:first-child {
  padding-right: 8px;
}
.experiment-data-navbar.history-tabs
  div
  span.rp-context
  span:not(.history-date).segment-names:not(:first-child) {
  border-left: 2px solid var(--primary-color);
  padding-left: 8px;
}
.experiment-data-navbar.history-tabs div span.rp-context > span.history-date {
  border-top: 1px solid var(--primary-color);
  color: #121212;
}
.experiment-data-navbar.history-tabs div span.rp-ctas {
  display: inline-flex;
  column-gap: 5px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid #727272;
}
.experiment-data-navbar.history-tabs div span.rp-ctas .history-date {
  font-weight: var(--base-font-weight);
  font-size: 13px;
  line-height: 140%;
  color: #b9b9b9;
  max-width: fit-content;
  width: 100%;
}
/* .experiment-data-navbar.history-tabs div span.rp-ctas button {
  background-color: #404040;
  padding: 2px 10px;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  padding: 2px 10px;
  font-size: 13px;
  line-height: 150%;
  color: #ffff;
  width: 100%;
  max-width: 91px;
  border-radius: 2px;
} */
.experiment-data-navbar div.history-controllers-btns {
  width: 100%;
  background-color: transparent;
  /* max-width: 53%; */
  padding: 0;
  justify-content: space-between;
  gap: 8px;
  /* flex-wrap: wrap; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/* .experiment-data-navbar div.history-controllers-btns button:last-child {
  background-color: #404040;
} */
.splide,
.splide__track,
.splide__list {
  width: 100%;
}
/* @media (max-width: 1024px) {
	.experiment-data-navbar div.history-controllers-btns {
		max-width: 62%;
	}
} */
/* .experiment-data-navbar.history-tabs div span.rp-ctas button.active {
  background-color: var(--primary-color);
  color: #fff;
} */
.experiment-data-navbar .splide--slide {
  /* display: flex; */
  /* gap: 12px; */
  align-items: center;
}
.experiment-data-navbar.history-tabs .splide__slide > div {
  /* background-color: #222222;
	border-radius: 5px; */
  padding: 8px 12px;
  border: 1px solid;
  border-color: #404040;
}
.live-report-history-tab-wrapper {
  padding: 8px 15px 8px 12px;
}
/* .experiment-data-navbar.history-tabs .splide__arrow {
	background-color: transparent;
    border: 0.5px solid #b9b9b9;
    width: 24px;
    height: 24px;
    border-radius: inherit;
    position: relative;
    transform: none;
} */

.splide .splide__arrow svg {
  fill: #f1f1f1;
  width: 9px;
}
.splide .splide__arrow {
  height: 24px;
  width: 24px;
  border-radius: 5px;
  background: transparent;
  border: 0.5px solid #b9b9b9;
}
.splide .splide__arrow--next {
  right: -30px;
}
.splide .splide__arrow--prev {
  right: auto;
  left: -30px;
}
.splide .splide__arrow:disabled {
  display: none;
}
.splide .splide__arrows.splide__arrows--ltr {
  padding: 0;
}

/* .experiment-data-navbar.history-tabs .splide__arrow--prev {
	display: none;
} */
.experiment-data-navbar.history-tabs div span.rp-context > span.history-date {
  display: none;
}

.experiment-data-dropdown-pane,
.segment-dropdown-value {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 68.1%;
  flex-wrap: wrap;
}
.experiment-data-dropdown-pane {
  padding: 15px 0px;
  border-right: 1px solid #404040;
  column-gap: 12px;
  row-gap: 12px;
}
/* .experiment-dropdown-text {
    color: #f1f1f1;
    font-size: 18px;
    font-weight: var(--base-font-weight);
    line-height: 20px;
  }
  .experiment-dropdown-text::after {
    content: url(../assets/chevron\ icon.svg);
    vertical-align: middle;
    padding-left: 5px;
    text-transform: capitalize;
  }
  .experiment-dropdown-text,
  .segment-input {
    padding: 20px 0px;
  }
  .experiment-dropdown-item:not(:last-child) {
    margin-right: 30px;
  } */
.date-selector img {
  margin-left: 20px;
}
/* .experiment-data-navbar .date-selector span {
  float: left;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.experiment-data-navbar .date-selector span.rdrMonthAndYearPickers {
  justify-content: space-between;
}
.experiment-data-navbar .date-selector span.numdays {
  border-left: 1px solid var(--color-neon);
  padding-left: 6px;
  color: #ffffff;
  font-weight: 600;
  margin-left: 6px;
}
.experiment-data-navbar .date-selector span.range-selected {
  padding-left: 3px;
}
.experiment-dropdown-item {
  cursor: pointer;
}
.segment-data {
  display: flex;
}
.segment-input {
  padding-left: 20px;
}
.segment-dropdown-value .experiment-dropdown-text,
.segment-input {
  width: 100%;
  max-width: 25%;
  padding: 11px 0px;
}
.segment-dropdown-value {
  padding: 0;
  position: relative;
}
.segment-dropdown-value .segment-input::before {
  content: "";
  width: 1px;
  background-color: #404040;
  top: 0;
  bottom: 0;
  position: absolute;
  display: block;
}
.segment-button {
  max-width: 85px;
  width: 100%;
  padding: 0px 10px;
}
.segment-button button {
  background-color: var(--color-neon);
  text-decoration: none;
  border: none;
  border-radius: 3px;
  text-transform: none;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--base-background-card);
  font-size: 15px;
  transition: 0.3s all ease-in-out;
}
.segment-button button:hover {
  box-shadow: 0px 0px 3px 3px rgb(27 196 74 / 30%);
}

button.update-segment-button {
  background-color: var(--primary-color);
  text-decoration: none;
  border: none;
  border-radius: 3px;
  padding: 0px 12px;
  text-transform: none;
  width: 100%;
  font-size: 15px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--base-white-color);
}
.experiment-segment {
  border-bottom: 1px solid #404040;
}
.segment-search {
  width: 100%;
  height: 100%;
  font-size: 18px;
  background-color: transparent;
  border: none;
  font-family: "Neue Haas Grotesk Text Pro", "sans-serif";
  line-height: 29px;
  color: var(--base-white-color);
  outline: none;
  padding-left: 15px;
}
.segment-search::placeholder {
  color: var(--base-white-color);
  font-size: 15px;
  font-weight: var(--base-font-weight);
  height: 100%;
  align-items: center;
}
.segment-btn {
  flex-grow: 1;
  position: relative;
}
.segment-data .segment-btn.precalc {
  padding: 10px 0px;
}
.segment-data .segment-btn.precalc > button {
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
}
.save-segment-btn {
  /* position: absolute; */
  background-color: var(--primary-color);
  border-radius: 5px !important;
  text-decoration: none;
  border: none;
  border-radius: 0px;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  min-height: 60px;
  align-items: center;
  color: var(--secondary-white-color);
}
.selected-segment {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.selected-segment .selected-data-wrapper {
  width: 100%;
  max-width: 60%;
}

.selected-segment .selected-data {
  margin-right: 5px;
  margin-bottom: 3px;
}
.selected-data {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: rgba(109, 198, 134, 0.22);
  border-radius: 5px;
  text-transform: capitalize;
  padding: 7.5px 8px;
}
.save-segment {
  display: flex;
  align-items: center;
  max-width: 45%;
  width: 100%;
  justify-content: flex-end;
}
.save-segment .save-segment-title {
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 20px;
  color: var(--secondary-white-color);
  margin-right: 30px;
  margin-left: 30px;
}
.selected-segment .save-segment input {
  height: 0;
  width: 0;
  visibility: hidden;
}

.selected-segment .save-segment label {
  cursor: pointer;
  text-indent: -9999px;
  width: 43px;
  height: 13px;
  background: #727272;
  display: block;
  border-radius: 100px;
  position: relative;
}

.selected-segment .save-segment label::after {
  content: "";
  position: absolute;
  top: -4px;
  left: 0px;
  width: 21px;
  height: 21px;
  background: var(--base-white-color);
  box-shadow: 0px 2px 6px rgba(3, 15, 45, 0.3);
  border-radius: 90px;
  transition: 0.3s all ease-in-out;
}

.selected-segment .save-segment label.checked {
  background: #7498f1;
}
.selected-segment .save-segment label.checked::after {
  left: calc(100%);
  transform: translateX(-100%);
  background-color: var(--primary-color);
  box-shadow: 0px 2px 6px rgba(3, 15, 45, 0.3);
}
.selected-data-name {
  color: var(--success-color);
  font-size: 13px;
  line-height: 14px;
  text-transform: none;
  font-weight: var(--base-font-weight);
  padding-left: 8px;
}
.remove-data-icon {
  display: inline-flex;
  cursor: pointer;
}
.impression-table {
  /* width: 980px; */
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 20px;
  margin-top: 20px;
}
.impression-table > * {
  min-width: 1125px;
}

.impression-table::-webkit-scrollbar-track {
  background-color: #404040;
  border-radius: 5px;
}

.impression-table::-webkit-scrollbar {
  height: 4px;
  background-color: #404040;
  border-radius: 5px;
}
.impression-table::-webkit-scrollbar-thumb {
  background-color: #727272;
  width: 389px;
  border-radius: 5px;
}
.live-reports-content .impression-table::-webkit-scrollbar-thumb {
  width: initial;
}

.impression-table-heading {
  margin-top: 20px;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 15px;
  color: var(--base-white-color);
  font-weight: var(--base-font-weight);
}
.product-page .impression-table .impression-table-heading:first-child {
  margin-top: 0px;
}
.impressions {
  display: flex;
  padding: 15px 34px;
  justify-content: space-between;
  border: 1px solid #727272;
  border-radius: 5px;
  gap: 5px;
}
.impression-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.impression-heading {
  padding-bottom: 9px;
}
.impression-heading h4 {
  color: #b9b9b9;
  font-size: 13px;
  line-height: 20px;
  font-weight: var(--base-font-weight);
  margin-bottom: 0px;
}
.impression-data .data-count {
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  color: var(--secondary-white-color);
}
.experiment-data .acc-newselect__placeholder,
.experiment-data .acc-newselect__input,
.experiment-data .acc-newselect__single-value {
  color: #f1f1f1 !important;
}
/* experiment-data-starts ends */

/* variant performance starts  */
section.variant-performance {
  margin-top: var(--reporting-section-gap);
}

.variant-performance-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0.6%;
  justify-content: space-between;
}
.live-reports + .experiment-data .variant-performance-cards {
  margin-top: 0px;
  justify-content: initial;
}
.single-performance-card,
.single-significance-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--base-background-card);
  border-radius: 5px;
  width: 100%;
  max-width: 24.5%;
}
.selected-test-content .single-performance-card {
  max-width: 22.8%;
}
.single-performance-card:has(br) {
  padding: 10.5px 20px;
}
.performance-card-heading,
.significance-card-heading {
  color: var(--base-white-color);
  text-transform: capitalize;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 17px;
  position: relative;
  padding-bottom: 10px;
  width: 100%;
  text-align: center;
}
.performance-card-heading::after,
.significance-card-heading::after {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 147px;
  margin: 0 auto;
  height: 1px;
  background-color: #727272;
  bottom: 0;
  left: 0;
  right: 0;
}
.performance-card-numbers,
.significance-card-numbers {
  font-weight: 500;
  font-size: 21px;
  /* line-height: 34px; */
  display: inline-flex;
  align-items: center;
  column-gap: 3px;
  color: var(--base-white-color);
  padding-top: 0px;
}
.performance-card-numbers small,
.significance-card-numbers small {
  font-size: 60%;
}
.number-danger {
  color: var(--danger-color);
}
.numbers-success {
  color: var(--success-color);
}
.time-to-process {
  font-weight: var(--base-font-weight);
  font-size: 18px;
  line-height: 25px;
  color: var(--base-white-color);
  margin-top: 30px;
  display: inline-block;
}
.reporting-graph-inner .section-heading {
  margin-top: var(--reporting-section-gap);
}
.reporting-subtitle {
  font-weight: var(--base-font-weight);
  font-size: 18px;
  line-height: 25px;
  color: #b9b9b9;
  margin-bottom: 28px;
}
.tab-component {
  border-bottom: 1px solid #727272;
  display: flex;
  /* margin-top: 30px; */
}
.tab-component .tab {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 5px 5px 0px 0px;
  background-color: var(--base-background-card);
  width: 100%;
  /* max-width: fit-content; */
  margin-right: 1px;
  justify-content: center;
}
.tab-component .tab-title {
  font-size: 18px;
  line-height: 20px;
  font-weight: var(--base-font-weight);
  color: #b9b9b9;
  text-transform: capitalize;
}
.tab-component .tab-active {
  background-color: var(--base-white-color);
}
.tab-component .tab-active .tab-title {
  color: var(--base-background-card);
}
.graph-depicts {
  display: flex;
  margin: 30px 0px;
}
.graph-depicts .depict {
  text-transform: capitalize;
  font-size: 20px;
  line-height: 22px;
  color: var(--base-white-color);
  font-weight: var(--base-font-weight);
}
.graph-depicts .depict:nth-child(1) {
  margin-right: 60px;
}
.depict .point {
  padding-right: 10px;
}
.splide.is-initialized,
.splide.is-rendered {
  max-width: 97.5%;
}
.selected-test-content.live-reports-content .single-performance-card {
  max-width: 32.5%;
}
.calculated-kpis-variants .single-performance-card {
  max-width: 32.5%;
}
/* variant performance ends  */

/* significance section begins  */
section.significance-calculation {
  margin-top: var(--reporting-section-gap);
}
.significance-content {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}
.significance-content .significance-text {
  font-size: 18px;
  line-height: 25px;
  font-weight: var(--base-font-weight);
  color: #b9b9b9;
  width: 100%;
  max-width: 35.4%;
  margin-bottom: 0px;
}

.show-data-btn {
  background-color: var(--primary-color);
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-white-color);
  padding: 18px 0px;
  width: 100%;
}
.showhidesignificance-cta {
  width: 100%;
  max-width: 362px;
}
.significance-cards {
  display: flex;
}
.significance-card-heading {
  text-transform: capitalize;
}
.single-significance-card:not(:last-child) {
  margin-right: 10px;
}
.significance-cards .variant-performance-cards {
  justify-content: initial;
}
/* significance section ends  */
@keyframes glowing {
  from {
    box-shadow: 0px 0px 10px 1px rgba(182, 240, 198, 0.2);
  }
  to {
    box-shadow: 0px 0px 25px 4px rgba(182, 240, 198, 0.3);
  }
}
/* REPORTING CSS ENDS */

/* TEST TIME LINE */
#test-timeline {
  margin-top: 41px;
}

#test-timeline .year-select {
  display: flex;
  justify-content: space-between;
}

#test-timeline .current-year-selected {
  font-weight: var(--base-font-weight);
  font-size: 21px;
  line-height: 24px;
  color: var(--base-white-color);
}

#test-timeline .month-select {
  margin-top: 30px;
}

#test-timeline .month-list {
  display: flex;
  justify-content: space-between;
  max-height: 23px;
  align-items: center;
  padding-bottom: 7px;
  border-bottom: 1px solid var(--base-white-color);
}
#test-timeline .month-list .month {
  font-size: 13px;
  line-height: 20px;
  text-transform: capitalize;
  color: #727272;
  font-weight: var(--base-font-weight);
  cursor: pointer;
}
#test-timeline .month-list .month-selected {
  line-height: 50px;
  font-weight: var(--base-font-weight);
  font-size: 40px;
  text-transform: uppercase;
  color: var(--base-white-color);
  background-color: #121212;
  padding: 0px 12px;
}
.test-timeline-details {
  margin-top: 54px;
  display: flex;
}
.test-timeline-details .test-select {
  width: 100%;
  max-width: 31.5%;
}

.test-timeline-card-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 60vh;
  /* min-height: 60vh; */
  overflow-x: hidden;
  border-right: 1px solid #404040;
}
.test-timeline-card-single {
  padding: 24px 0px;
  position: relative;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.test-timeline-card-single::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #404040;
  transition: 0.3s all ease-in-out;
}
.timeline-card-title {
  font-weight: var(--base-font-weight);
  font-size: 21px;
  line-height: 24px;
  margin-bottom: 0px;
  color: var(--base-white-color);
  overflow-wrap: anywhere;
}
.timeline-card-divider {
  margin-top: 24px;
}
.timeline-card-divider p {
  margin-bottom: 0px;
}
.timeline-card-divider p:nth-child(2) {
  margin: 4px 0px;
}
.timeline-card-divider .header-content {
  color: var(--base-white-color);
  font-size: 13px;
  line-height: 20px;
  font-weight: var(--base-font-weight);
}

.timeline-card-divider .header {
  color: #b9b9b9;
  text-transform: capitalize;
  font-size: 13px;
}
.timeline-card-selected {
  background-color: var(--base-background-card);
  padding-left: 24px;
}
.timeline-card-selected::before {
  left: 0;
  height: 3px;
  background-color: var(--secondary-white-color);
}
.test-timeline-card-single .no-uplift {
  color: var(--danger-color);
}
.test-timeline-card-single .winner {
  color: var(--success-color);
}
.test-timeline-card-list::-webkit-scrollbar {
  width: 2px;
  background-color: #404040;
}
.test-timeline-card-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #404040;
}

.test-timeline-card-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #727272;
  width: 4px;
}
.selected-test-content {
  width: 100%;
  padding-left: 40px;
  max-width: 75%;
  /* overflow-x: auto;
  overflow-y: hidden; */
  border-left: 1px solid;
}
.report-status-box {
  width: fit-content;
  padding: 0 8px;
  margin-left: 10px;
  color: #000;
  border-radius: 5px;
}
.report-status-factor {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
.report-status-value {
  font-size: 16px;
  font-weight: 100;
  text-transform: capitalize;
}
.report-status-success {
  background-color: #1b8e57;
}
.report-status-danger {
  background-color: #d71f44;
}
.report-status-warning {
  background-color: #f7ca2c;
}
.selected-test-content.live-reports-content {
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 100%;
  border-left: none;
}
/* TESTS TIME LINES ENDS */

/* CLIENT INSIGHTS */

.product-card-view {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* width: 100%;
  max-width: 33%; */
  column-gap: 8px;
  background: #222;
  border-radius: 3px;
  padding: 3px 5px;
}

.product-card-view .sort-icon {
  margin-left: 16px;
}
.product-page-search .view-active {
  background-color: var(--base-background-card);
  border-radius: 5px;
  /* padding: 8.6px;
    max-width: 42px;
    max-height: 42px; */
}
.product-page-search .product-card-view svg {
  /* padding: 3px; */
}
.product-page-search .product-card-view svg path {
  fill: #727272;
}
.product-page-search .product-card-view .view-active svg path {
  fill: var(--base-font-color);
}
.grid-icon {
  display: flex;
}
/* .menu-view {
    max-height: 21.33px;
  } */

.product-card-view .sort-icon,
.product-card-view .grid-icon,
.product-card-view .menu-icon,
.page-card-img img {
  cursor: pointer;
  padding: 4px;
}


:is(.grid-icon, .menu-icon) svg {
  width: 20px;
  height: 20px;
}

/* client search ends  */

/* client insight card starts  */
.client-insight-card-list {
  display: flex;
  padding-top: 34px;
  padding-bottom: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 7px;
  transition: 0.8s all cubic-bezier(0.4, 0, 0.2, 1);
  /* border-bottom: 1px solid var(--base-white-color); */
}

.client-insight-card-list .list-view-headings {
  display: none;
}
.client-insight-card {
  display: inherit;
  flex-direction: column;
  padding: 13px 20px;
  background-color: var(--base-background-card);
  border-radius: 5px;
  width: 100%;
  max-width: 32.8%;
}
.insight-card-heading {
  font-size: 15px;
  font-weight: var(--base-font-weight);
  line-height: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 16px;
  word-break: break-word;
  color: var(--base-font-color);
  text-transform: capitalize;
  margin: 0;
}
.insight-card-details {
  padding: 12px 0px;
  border-top: 1px solid #727272;
  border-bottom: 1px solid #727272;
}
.insight-card-details .insight-results {
  display: flex;
}
.insight-results .insight-result-wrapper {
  display: inherit;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}
.client-insight-card :is(.card-detail-head, .card-detail-data) {
  font-size: 15px;
  font-weight: var(--base-font-weight);
  line-height: 17px;
  color: #b9b9b9;
  text-transform: capitalize;
}
.insight-card-details .card-detail-head:nth-child(2) {
  margin-top: 12px;
}
.insight-card-details .card-detail-data {
  margin-top: 4px;
}
.insight-result-wrapper .insight-result-success {
  color: var(--success-color);
}
.client-insight-card .insight-trigger-list {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.insight-trigger-list .single-trigger {
  font-size: 12px;
  line-height: 13px;
  text-transform: capitalize;
  color: var(--base-white-color);
  padding: 6px 10px;
  background-color: var(--primary-color);
  border-radius: 2px;
}
.insight-card-learnings {
  margin-top: 12px;
  height: 100%;
}
.insight-learning-content {
  font-size: 13px;
  font-weight: var(--base-font-weight);
  line-height: 18px;
  color: var(--base-white-color);
  margin-top: 4px;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.insight-trigger-list:not(:has(.single-trigger)) {
  margin-top: 0px;
}

/* client-insight card ends  */

/* list view of insight begins  */
.insight-list-view {
  flex-direction: column;
  row-gap: 0px;
  padding-top: 0px;
}
.insight-list-view .list-view-headings {
  display: flex;
  /* width: 100%; */
  margin-top: 40px;
  align-items: center;
  padding: 10px 12px;
  padding-top: 0px;
  gap: 20px;
  border-bottom: 1px solid var(--base-white-color);
}
.list-view-headings .list-view-heading-single {
  text-transform: capitalize;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 17px;
  color: #727272;
}
.insight-list-view .client-insight-card {
  max-width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 15px 12px;
  background-color: transparent;
  border-radius: 0px;
  gap: 20px;
}

.insight-list-view .client-insight-card:nth-child(odd) {
  background-color: var(--base-background-card);
}

.insight-list-view .insight-card-heading {
  text-align: initial;
  padding: 0;
  font-size: 13px;
  line-height: 19px;
  color: #ffff;
}
.insight-list-view :is(.insight-card-details, .insight-results) {
  display: contents;
}
.insight-list-view :is(.card-detail-head, .trigger-head) {
  display: none;
}
.insight-list-view .insight-result {
  line-height: 21px;
  font-size: 13px;
  margin: 0;
}
.insight-list-view .tags-data {
  order: 1;
  visibility: hidden;
}
.insight-list-view .insight-trigger-list {
  display: inline-flex;
  order: 0;
  align-items: center;
  margin: 0;
}

.insight-list-view .insight-card-learnings {
  order: 2;
  margin: 0;
}
.insight-list-view .insight-learning-content {
  word-wrap: break-word;
  overflow: visible;
  display: inherit;
  margin: 0;
}

.insight-list-view :is(.test-head, .insight-card-heading) {
  flex-basis: 22.6%;
}
.insight-list-view
  :is(.result-head, .insight-result, .triggers-head, .insight-trigger-list) {
  flex-basis: 18.1%;
}
.insight-list-view :is(.tag-head, .tags-data) {
  flex-basis: 13.6%;
}
.insight-list-view :is(.learning-head, .insight-card-learnings) {
  flex-basis: 27.2%;
}
/* list view of insights ends  */
/* CLIENT INSIGHTS ENDS */

/*SEND FEEDBACK  */
section.feedback {
  width: 100%;
}

.feedback-inner {
  display: flex;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  max-width: 55.12%;
}

.feedback-wrapper {
  width: 100%;
}
.feedback-wrapper .feedback-title {
  text-align: center;
  font-size: var(--heading-size);
  line-height: var(--heading-line-height);
  font-weight: var(--base-font-weight);
  text-transform: capitalize;
  color: #ffff;
  margin-bottom: 36px;
}
.input-search {
  width: 100%; 
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.feedback-wrapper .input-search .product-search {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: "Neue Haas Grotesk Text Pro", "sans-serif";
  line-height: 29px;
  width: 100%;
  font-size: 26px;
  color: var(--base-white-color);
  padding-bottom: 10px;
  border-bottom: 1px solid var(--base-white-color);
  padding-left: 0px;
}
.feedback-wrapper .product-page-search > form {
  max-width: 100%;
  width: 100%;
}

.ck.ck-editor__editable_inline[dir="ltr"] {
  min-height: 300px;
}
.ck.ck-editor__main > .ck-editor__editable,
.ck.ck-toolbar {
  background-color: var(--base-background-card) !important;
  color: var(--base-font-color);
  border: none !important;
  position: relative;
}
.ck.ck-toolbar::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  height: 1px;
  max-width: 98%;
  margin: 0px auto;
  background-color: var(--base-background-color);
}
.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: var(--base-font-color) !important;
}
.search-bar .product-search:focus {
  outline: none;
}
.ck-editor {
  margin: 30px 0px;
}

.send-feedback-btn {
  font-weight: 500;
  width: 100%;
  border: none;
  min-height: 57px;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  text-decoration: none;
  background-color: var(--primary-color);
  color: var(--base-white-color);
}

/* feedback ends  */

/* TEST LISTS PAGE */
.test-details-table {
  width: 100%;
  margin-top: 24px;
}
.test-details-table .table-head {
  padding: 0px 12px;
}

h2.test-details-heading-single {
  text-align: left;
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 18px;
  line-height: 110%;
  text-transform: capitalize;
  color: #727272;
}
.test-details-table-data {
  display: flex;
  column-gap: 2%;
  padding: 17px 12px;
  align-items: center;
  justify-content: space-between;
}
.test-details-table-data ul {
  padding-left: 0px;
  list-style: none;
  margin-bottom: 0px;
}
.test-details-table-data ul li {
  padding: 3px 0px;
  color: #003ed4;
}
.snippets-overflow .snippet-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}
.snippets-overflow pre {
  max-height: 400px;
}
.snippets-overflow .snippet-heading p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #003ed4;
  font-weight: 600;
}
.snippets-overflow .snippet-heading button {
  border: none;
  background-color: #003ed4;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  padding: 5px 10px;
}
.test-details-table-data ul li button {
  color: #fff;
  background-color: #003ed4;
  border: none;
  width: 100%;
  border-radius: 2px;
}
/* .test-details-table-data ul li:not(:last-child){
  border-bottom: 1px solid #fff;
} */
.test-details-table-data ul li span {
  color: #f1f1f1;
}
.test-details-table-data.heading-row {
  padding: 20px 12px;
}
.test-details-table-data > div {
  max-width: calc(100% / 5);
  width: 100%;
  color: #fff;
}
.test-details-table-data > div.test-email {
  max-width: calc(100% / 5);
  width: 100%;
  color: #fff;
}
.test-details-table-data > div.tested-count,
.test-details-table-data > div.client-hypothesis-list,
.test-details-table-data > div.targeted-page-object,
.test-details-table-data > div.hypothesis-tagged,
.test-details-table-data > div.tested-won {
  max-width: 130px;
  position: relative;
  text-align: center;
}
.text-wrap-wrap {
  white-space: pre-wrap;
}
div.clientlist-tip {
  /* opacity: 0; */
  /* visibility: hidden; */
  position: absolute;
  width: 100%;
  max-width: 160px;
  max-height: 210px;
  overflow-x: auto;
  z-index: 1;
  background-color: var(--base-background-color);
  border: 1px solid #404040;
  border-radius: 5px;
  transform: translate(0, 15px);
  padding: 12px 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 130%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word;
  text-align: left;
  row-gap: 8px;
  /* width: auto; */
}
div.clientlist-tip::-webkit-scrollbar {
  width: 3px;
}

div.clientlist-tip::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

div.clientlist-tip::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
div.clientlist-tip ul {
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  max-width: 100%;
}
div.clientlist-tip ul > li:not(:last-child) {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #f1f1f10f;
}
.test-details-table-data > div.hypothesis-name {
  max-width: 15%;
}
.test-details-table-data > div.hypothesis-details {
  max-width: 50%;
}
.test-details-table-data > div.hypothesis-details p {
  font-weight: var(--base-font-weight);
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 5px;
}
.test-details-table-data > div.hypothesis-details p > span {
  font-weight: 500;
  color: var(--success-color);
}
.test-details-table-data > div.action {
  max-width: 60px;
  text-align: right;
}
.test-details-table-data > div.test-compare-img svg {
  cursor: pointer;
}
.test-details-table-data > div.test-compare-img,
.test-details-table-data > div.test-status,
.test-details-table-data > div.test-action-wrapper {
  text-align: center;
}
.test-details-table-data.heading-row > div * {
  text-align: left;
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 18px;
  line-height: 110%;
  text-transform: capitalize;
  color: #727272;
}
.test-details-table .test-details-table-headings {
  display: flex;
  gap: 2%;
}
.test-details-table-data .test-action {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  justify-content: center;
}
.test-details-table-data .action-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.test-details-table-data .action-group svg {
  margin-bottom: 7px;
}
.test-details-table-data .action-group span {
  font-weight: var(--base-font-weight);
  font-size: 12px;
  line-height: 130%;
  color: #727272;
}
.test-details-table-data h3 {
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  margin: 0;
}
.test-details-table-data .test-name h3 {
  word-wrap: break-word;
}

.landing-page-screenshot {
  height: 450px;
  width: 500px;
  object-fit: contain;
}

.test-details-table-data .test-status * {
  font-weight: var(--base-font-weight);
  font-size: 16px;
  line-height: 130%;
  text-transform: capitalize;
}
.test-details-table-data .test-status > span.ended {
  color: #b03232;
}
.test-details-table-data .test-status > span.ended button.remove-report {
  margin-left: 6px;
  border-radius: 3px;
  border: none;
  background-color: #b03232;
  font-size: 12px;
  color: #fff;
  padding: 3px 5px;
}
.test-details-table-data .test-status > span.live {
  color: #1bc44a;
}
.test-details-table-data .test-status > span.discard {
  color: var(--primary-color);
  text-decoration: line-through;
}
.test-details-table-data .test-status > span.draft {
  color: coral;
}
.test-details-table .table-body .test-details-table-data:nth-child(even) {
  background-color: var(--base-background-color);
}
.selected-test-content.live-reports-content .experiment-data-inner {
  margin-top: 20px;
}
.generate-update-report-margin-top{
  margin-top: 35px;
}

/* 
.live-reports-content .experiment-data-inner:last-child {
  margin-top: 60px;
} */
.live-reports-content .tab-component {
  margin: 25px 0px;
}
.experiment-data-inner .date-selector {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
}

.reporting-mobile-filters {
  display: none;
}
.reporting-mobile-filters__text {
  font-weight: 400;
  font-size: 9px;
  line-height: 130%;
  text-transform: uppercase;
  color: #727272;
}
/* TESTS LISTS PAGE ENDS */

/* Your link page css starts  */
.list-client-documents > .links-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  margin-top: 24px;
}
.links-wrapper .single-link-wrapper {
  width: 100%;
  max-width: 32.13%;
  background-color: #222222;
  border-radius: 5px;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  transition: 0.2s all ease-in-out;
}
.single-link-wrapper .link-details {
  width: 100%;
  max-width: 77.7%;
}
.single-link-wrapper .link-details > * {
  font-weight: var(--base-font-weight);
  font-size: 24px;
  line-height: 130%;
  color: var(--base-white-color);
  margin-bottom: 8px;
}
.single-link-wrapper .link-details > p {
  font-size: 15px;
}
.single-link-wrapper:hover {
  /* background-color: var(--primary-color); */
  cursor: pointer;
}
.users-filters {
  overflow: auto;
  white-space: nowrap;
  position: relative;
  scrollbar-width: none;
}
.users-filters::-webkit-scrollbar {
  display: none;
}

/* client business case projection */
.client-business-case  .smart-input h1 {
  color: #FFF;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}

.client-business-case .smart-input .experiment-name {
padding: 16px
}
.client-business-case .smart-input .experiment-name p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* client business case projection end */
/* Your link page css ends  */

@media (max-width: 1439px) {
  .analytics-card {
    max-width: 100%;
    min-height: 120px;
    padding: 15.25px 20px;
  }
  .product-card-list-view .product-page-card,
  .product-card-list-view .list-view-headings {
    gap: 1%;
  }
  .product-card-list-view .product-page-card > div:first-child,
  .product-card-list-view .list-view-heading-single:first-of-type {
    min-width: 25%;
  }
  .analytics-card-figures {
    padding-top: 20px;
  }
  .average-revenue-title {
    margin-bottom: 45px;
  }
  .tests-overview .top-learning-inner,
  .top-learning .top-learning-inner {
    flex-wrap: wrap;
  }
  .learning-list {
    max-width: 100%;
  }
  section.top-learning .learning-list {
    margin-bottom: 30px;
  }
  .steps-wrapper {
    column-gap: 1%;
    row-gap: 8px;
  }
  .top-learning .steps-wrapper .current-step:not(:last-child) {
    max-width: 49.5%;
  }
  .next-steps {
    max-width: 100%;
  }
  .product-card-list-view .product-page-card {
    display: flex;
    justify-content: space-between;
  }
  .test-timeline-card-list {
    min-height: 100%;
  }
  .test-timeline-details .test-select {
    max-width: 30%;
  }
  .timeline-card-title {
    font-size: 20px;
  }
  .variant-performance-cards {
    /* gap: 10px; */
    justify-content: inherit;
  }
  /* .time-line-variant-heading > .variant-performance-cards {
    gap: 10px;
  } */

  .selected-test-content .single-performance-card {
    max-width: 32%;
  }
  .reporting-tab-component {
    margin-bottom: 20px;
  }
  /* .variant-performance-cards .single-performance-card {
    max-width: 23.5%;
  } */
  .header-wrapper .header-inner span:not(.name) {
    font-size: 13px;
  }
  .account .user-name .name {
    font-size: 18px;
  }
}
@media (max-width: 1366px) {
  .experiment-list {
    gap: 5px;
    justify-content: inherit;
  }
  /* .experiment-name{
    max-width: 25%;
  } */
}
@media (max-width: 1279px) {
  .report-details-modal .report-details-modal-content-wrapper {
    padding: 15px 15px;
    max-width: 95%;
  }
  .sidebar {
    height: calc(100vh - 93px);
  }
  .tab-component .tab-title {
    font-size: 16px;
  }
  .acc-newselectwrapper .acc-newselect__menu {
    padding: 5px 5px;
  }
  .report-details-modal .close-modal > button {
    right: 0px;
    top: 0px;
  }
  section.experiment-data {
    margin-top: 15px;
  }
  .experiment-data-dropdown-pane {
    border-right: none;
  }
  .live-icon {
    margin-right: 10px;
  }
  .client-logo-wrap .client-logo img {
    max-width: 115px;
  }
  .average-revenue-date-selector .acc-newselectwrapper {
    max-width: 100px;
  }
  .splide .splide__arrow--next {
    right: -18px;
  }
  .splide .splide__arrow--prev {
    right: auto;
    left: -18px;
  }
  .client-stats {
    padding: 4px 0px 4px;
  }
  .analytics-text,
  .average-revenue-title {
    font-size: 26px;
    line-height: 100%;
    margin-bottom: 16px;
  }
  .analytics-card-title {
    font-size: 14px;
    line-height: 110%;
    margin-bottom: 0px;
  }
  .month-card .monthly-test-list {
    margin: 16px 0px 0px;
  }
  .analytics-card-figures .analytics-amount {
    font-size: 17px;
    line-height: 110%;
  }
  /* .client-layout-content {
    padding: 30px 25px;
    min-height: calc(100vh - 93px);
  } */
  .authlayout-inner {
    /* padding-top: 93px; */
  }
  /* .sidebar-is-open .sidebar {
    max-width: 320px;
  } */
  /* .authlayout-wrapper.sidebar-is-open .client-layout-content {
    max-width: calc(100% - 320px);
    margin-left: calc(320px);
    padding: 30px 25px;
  } */
  .analytics-cards-list .analytics-card {
    min-height: 77px;
  }
  .top-learning .steps-wrapper .current-step:not(:last-child) {
    max-width: 100%;
  }
  .client-stats,
  .experiment-data-navbar {
    flex-wrap: wrap;
  }
  .experiment-data-navbar {
    padding: 0px 0px;
  }
  section.strategy,
  section.top-learning,
  section.tests-overview,
  .client-average-revenue,
  section.product-page {
    padding: 14px 0px;
  }
  .strategy-title,
  .learning-list-heading,
  .next-steps-heading,
  .learning-list-heading {
    font-size: 26px;
    line-height: 100%;
    margin-bottom: 16px;
  }
  .tests-overview .test-overview-box h4 {
    margin: 4px 0px 12px;
  }
  .stratergy-months-card-list {
    gap: 0;
    margin-top: 16px;
    flex-wrap: wrap;
  }
  .stratergy-months-card-list .month-card {
    max-width: 100%;
    flex: auto;
    padding: 20px 20px;
  }
  .tests-overview .test-overview-box h3,
  .tests-overview .current-step h3 {
    font-size: 16px;
  }
  .tests-overview .test-overview-box p {
    font-size: 14px;
    line-height: 130%;
  }
  .tests-overview .test-overview-box h4,
  .tests-overview .current-step p {
    font-size: 20px;
    line-height: 110%;
  }
  .tests-overview .learning-list,
  .tests-overview .current-step {
    padding: 16px 16px;
  }
  .learning-list-content {
    padding: 16px 14px;
  }
  .icon {
    margin-right: 7px;
  }
  .strategy-sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    margin: 8px 0px 0px;
  }
  .client-analytics,
  .client-average-revenue {
    max-width: 100%;
  }
  .client-analytics {
    margin-bottom: 14px;
  }
  .month-card-content .month-name {
    font-size: 20px;
    line-height: 110%;
  }
  .test-detail .icon {
    margin-right: 7px;
  }
  .monthly-test-list .task-status,
  .learning-list-content .learning-text {
    font-size: 14px;
    line-height: 130%;
  }
  /* .product-page-card-list .product-page-card {
    max-width: 49%;
  } */
  .learning-list-content li:not(:last-child),
  .monthly-test-list .test-detail:not(:last-child) {
    margin-bottom: 12px;
  }
  .product-page-search .search-wrapper {
    align-items: center;
  }
  .product-card-view .sort-by {
    display: none;
  }
  /* .selected-test-content .single-performance-card {
    max-width: 48%;
  } */
  .test-timeline-details {
    flex-direction: column;
    margin-top: 40px;
  }
  .test-timeline-details .test-select {
    /* display: flex;
    flex-direction: row; */
    max-width: 100%;
  }
  /* .test-timeline-card-list {
    width: 100%;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: scroll;
  } */
  .test-timeline-card-list {
    border-bottom: 1px solid #404040;
    border-right: none;
  }
  .test-timeline-card-single {
    width: 100%;
    padding: 20px;
  }
  .test-timeline-card-list::-webkit-scrollbar-track {
    background-color: #404040;
    border-radius: 5px;
  }
  .tiers-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  .tiers-list > button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .test-timeline-card-list::-webkit-scrollbar {
    height: 4px;
    background-color: #404040;
    border-radius: 5px;
  }
  .test-timeline-card-list::-webkit-scrollbar-thumb {
    background-color: #727272;
    width: 389px;
    border-radius: 5px;
  }
  .selected-test-content {
    max-width: 100%;
    padding-left: 0;
    border-left: none;
  }
  .selected-test-content-title {
    padding: 30px 0px;
  }
  .selected-comparison-head {
    margin-bottom: 0px;
  }
  .client-insight-card-list {
    column-gap: 7px;
  }
  .client-insight-card {
    max-width: 49.5%;
  }
  .experiment-data-dropdown-pane {
    max-width: 100%;
    /* justify-content: space-between; */
  }
  .experiment-data-navbar .date-selector {
    max-width: 100%;
    border-top: 1px solid #404040;
    border-right: none;
    text-align: right;
  }
  .experiment-data-dropdown-pane,
  .segment-dropdown-value {
    padding: 10px 0px;
  }
  .header-wrapper .header-inner span:not(.name) {
    font-size: 10px;
    margin-top: 2.5px;
  }
  .header-wrapper .header-inner svg:not(:last-child) {
    width: 28px;
    height: 28px;
  }
  .account .user-name .name {
    font-size: 14px;
  }
  /* .report-head-main .section-heading-title {
    font-size: 25px;
  } */
  .live-reports-inner .section-heading {
    font-size: 35px;
  }
  .experiment-data-navbar .date-selector {
    padding: 10px 0px;
    justify-content: flex-end;
  }
  .experiment-segment .segment-data {
    display: contents;
  }
  .experiment-segment .segment-dropdown-value {
    max-width: 100%;
    justify-content: space-between;
  }
  .segment-dropdown-value .experiment-dropdown-text,
  .segment-input {
    padding: 0;
  }
  .experiment-data-inner .experiment-segment {
    border: none;
  }
  .segment-dropdown-value {
    border-bottom: 1px solid #404040;
  }
  .segment-data .segment-btn {
    height: 50px;
    margin-top: 10px;
    max-width: 25%;
    width: 100%;
  }
  .segment-data .segment-btn.precalc {
    padding: 10px 0px;
  }
  .segment-btn .save-segment-btn {
    border-radius: 5px;
  }
  .experiment-data-inner .experiment-segment {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  section.experiment-data .impressions {
    padding: 15px;
  }
  .test-details-table-data > div.tested-count,
  .test-details-table-data > div.client-hypothesis-list,
  .test-details-table-data > div.targeted-page-object,
  .test-details-table-data > div.hypothesis-tagged,
  .test-details-table-data > div.tested-won {
    max-width: 70px;
  }
}

@media (max-width: 1023px) {
  .client-layout-content{
    padding: 20px;
  }
  .authlayout-inner main, .sidebar-is-open .authlayout-inner main{
    margin-left: auto;
    max-width: 100%;
    margin-right: auto;
  }
  .announce-wrapper .announce-inner .announce-details.hide {
    display: block !important;
  }
  .announce-wrapper {
    position: initial;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .visual-box-inner {
    flex-direction: column;
    align-items: normal;
    row-gap: 12px;
  }
  .significance-content .significance-text {
    max-width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
  }
  .selected-test-content .single-performance-card,
  .selected-test-content.live-reports-content .single-performance-card {
    max-width: 32%;
  }
  .experiment-name {
    padding: 12px 5px;
  }
  .product-page-search {
    padding: 15px 0px;
  }
  .product-page-title > .insights {
    font-size: 16px;
  }
  .product-page-title {
    margin: 16px 0px;
  }
  .segment-data .segment-btn,
  .selected-segment .selected-data-wrapper,
  .save-segment {
    max-width: 100%;
  }
  .save-segment {
    justify-content: center;
  }
  .selected-segment {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 12px;
  }
  .selected-data-name {
    padding-left: 4px;
  }
  .product-card-list-view .product-page-card,
  .product-card-list-view .list-view-headings {
    white-space: nowrap;
    /* overflow-x: auto; */
    column-gap: 15px;
    padding: 20px 12px;
  }

  .experiment-dropdown-item {
    max-width: 32%;
  }
  .experiment-data-dropdown-pane {
    column-gap: 2%;
  }
  .experiment-details {
    max-width: 100%;
  }
  .client-data-wrapper {
    flex-direction: column;
    margin-top: 15px;
  }

  .product-page-card-list.product-card-list-view {
    row-gap: 0px;
    overflow-x: scroll;
  }
  .product-card-list-view .product-page-card > div:first-child,
  .product-card-list-view .list-view-heading-single:first-of-type {
    min-width: -moz-fit-content;
    min-width: 185px;
    max-width: fit-content;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .steps-wrapper {
    column-gap: 0.5%;
    row-gap: 4px;
  }

  .tests-overview .current-step:not(:last-child) {
    max-width: 32.7%;
    padding: 12px 8px;
  }
  .tests-overview .top-learning-inner,
  .top-learning .top-learning-inner {
    gap: 5px;
  }
  .tests-overview .steps-wrapper {
    row-gap: 5px;
  }
  .tests-overview .learning-list,
  .tests-overview .current-step {
    padding: 12px 10px;
  }
  .top-learning .current-step {
    padding: 16px 10px;
  }
  .list-client-documents ul {
    column-gap: 2%;
  }
  .list-client-documents ul li span.title {
    font-size: 16px;
    line-height: 110%;
  }
  .list-client-documents ul li a svg {
    width: 14px;
    height: 14px;
  }
  .list-client-documents ul li a span {
    font-size: 10px;
    line-height: 110%;
    margin-top: 4px;
  }
  .list-client-documents ul li > span.description {
    font-size: 12px;
  }
  .list-client-documents ul li {
    max-width: 48%;
  }

  .header-bar .header-bar-inner {
    flex-wrap: wrap;
  }
  .header-bar + .table-body,
  .hypothesis-filter + .table-body {
    overflow-x: scroll;
  }
  .header-bar .header-bar-inner h2 {
    width: 100%;
    font-size: 26px;
  }
  .header-bar .header-bar-inner h4 {
    width: 100%;
    font-size: 16px;
  }
  .header-bar .header-bar-inner > div:last-child {
    flex-wrap: wrap;
    max-width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .test-details-table-data {
    /* flex-wrap: wrap; */
    min-width: 1000px;
  }
  .test-details-table-data h3,
  .test-details-table-data .test-status * {
    font-size: 14px;
  }
  .test-details-table-data.heading-row {
    border-bottom: 1px solid #f6f6f6;
    padding: 12px 12px;
  }
  .header-bar .header-bar-inner button.add {
    font-size: 15px;
    max-width: 50%;
  }

  .search-wrapper .search-icon {
    max-width: 18px;
  }
  .input-search .product-search {
    /* padding-left: 10px; */
  }
  .product-page-card {
    max-width: 49%;
  }
  .test-details-table-data > div {
    max-width: calc(100%);
  }
  .account .user-name .name,
  .user-name .dropdown {
    display: none;
  }
  .account .user-name::before {
    margin-right: 0px;
    height: 16px;
    width: 16px;
    font-weight: 400;
    font-size: 9px;
  }
  .account {
    align-items: center;
  }
  .header-wrapper .header-inner span:not(.name) {
    font-size: 9px;
    line-height: 11.3px;
  }
  .header-logo img {
    max-width: 199px;
    width: 100%;
  }
  /* .client-layout-content {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
    padding: 15px 8px;
    min-height: calc(100vh - 50px);
    overflow: auto;
    transition: 0.3s max-width ease-in-out;
  } */
  .sidebar-list a .sidebar-text,
  .sidebar-list a .dropicon {
    display: block;
    line-height: initial;
  }
  .sidebar-list a {
    justify-content: space-between;
  }
  .sidebar-list .icon {
    margin-right: 30px;
  }
  .sidebar {
    padding: 20px 10px;
    z-index: 1;
    display: block;
    transform: translateX(-100%);
    height: calc(100vh - 50px);
    transition: 0.3s transform ease-in-out;
  }
  .mobile-menu{
    display: block;
  }
  .authlayout-inner {
    /* padding-top: 50px; */
  }
  .header-wrapper .header-inner{
    justify-content: flex-end;
    padding: 0px 0px;
    column-gap: 15px;
  }
  .header-inner .account-details{
    max-width: fit-content;
    flex: auto;
  }
  .header-wrapper .header-inner .menu-icon{
    margin-left: auto;
    flex: 0;
    align-items: flex-end;
    gap: 20px;
  }
  .sidebar-list.logo {
    padding: 0px 0 10px;
}
  .sidebar-is-open .sidebar {
    /* display: block; */
    transform: translateX(0%);
    opacity: 1;
    z-index: 10;
  }
  .header-wrapper .header-inner svg:not(:last-child) {
    width: 16px;
    height: 16px;
  }
  .menu-icon {
    column-gap: 10px;
  }
  /* .authlayout-wrapper.sidebar-is-open .client-layout-content {
    max-width: 100%;
    margin-left: 0;
    padding: 15px 8px;
  } */
  .hypothesis-filter .single-filter {
    max-width: 18.7%;
  }
}

@media (max-width: 767px) {
  .experiment-dropdown-item,
  .segment-dropdown-value .experiment-dropdown-text,
  .segment-input {
    max-width: 100%;
    width: 100%;
  }
  .save-segment {
    display: none;
  }
  .selected-segment {
    margin-top: 3px;
  }
  .segment-input input {
    padding: 0px 10px;
  }
  .segment-button button {
    height: 26px;
    font-size: 12px;
  }
  .segment-button {
    max-width: 100%;
    width: 100%;
    padding: 5px 10px;
  }
  .experiment-data-navbar .date-selector span.numdays {
    border-left: 1px solid #003ed4;
    padding-left: 5px;
  }
  /* .menu-view.hide-on-mobile {
    display: none;
  } */

  .experiment-data-navbar .date-selector span {
    margin-left: 0px;
  }
  .experiment-heading-divider .showaovrpucta {
    font-size: 10px;
  }

  .insight-list-view .list-view-headings,
  .product-card-list-view .list-view-headings {
    min-width: 750px;
    margin-top: 18px;
  }
  .client-insight-card-list {
    padding-top: 18px;
    overflow-x: scroll;
  }
  .selected-test-content {
    /* border-top: 1px solid #fff; */
    margin-top: 16px;
  }
  .report-details-modal .report-details-modal-content-wrapper {
    max-width: 100%;
  }
  .report-details-modal-content-wrapper .selected-test-content {
    border-top: none;
  }
  .insight-list-view .client-insight-card {
    padding: 15px 12px;
    min-width: 750px;
  }
  .tab-dropdown-mobile button svg,
  .month-dropdown-mobile button svg {
    width: 15px;
    height: 15px;
  }
  .client-insight-card {
    max-width: 100%;
  }
  .product-page-title {
    font-size: 22px;
    margin: 15px 0px;
  }
  .product-page-title .insights {
    display: none;
  }
  #test-timeline {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    margin-top: 10px;
  }
  .product-page-search {
    margin-top: 10px;
  }
  .product-page .product-page-search {
    padding: 0px;
    margin-top: 13px;
  }
  .insight-search {
    padding: 10px 0px;
  }
  .input-search .product-search,
  .input-search .product-search::placeholder {
    font-size: 16px;
  }

  .tab-component .tab-title {
    line-height: 26px;
    font-size: 10px;
    color: #fff;
  }
  .graph-component {
    margin: 12px 0px;
    position: relative;
  }
  .performance-card-heading,
  .significance-card-heading {
    font-size: 12px;
    line-height: 120%;
    padding-bottom: 4px;
    margin-bottom: 0px;
  }
  .selected-test-content .selected-comparison-head {
    width: 100%;
    text-align: center;
  }
  .selected-test-content-title {
    margin: 12px 0px;
    flex-direction: column;
    row-gap: 10px;
    padding: 0px 0px;
  }
  /* .section-heading-subtitle{
  line-height: ;
} */
  .experiment-data-inner .section-heading {
    font-size: 26px;
  }
  .live-reports-inner .section-heading,
  .calculated-kpis-variants .variant-name {
    font-size: 22px;
    line-height: 110%;
  }
  .experiment-name p {
    font-size: 13px;
  }
  .significance-heading .section-heading {
    font-weight: 400;
    font-size: 26px;
  }
  .impression-table-heading {
    margin-top: 12px;
  }
  .impression-table > * {
    min-width: 780px;
  }
  .performance-card-numbers,
  .significance-card-numbers {
    font-size: 17px;
    line-height: 150%;
    padding-top: 0px;
    margin-top: 4px;
  }
  .variant-performance-cards,
  .time-line-variant-heading > .variant-performance-cards {
    gap: 4px;
    justify-content: inherit;
  }

  .selected-test-content .single-performance-card {
    max-width: 49%;
    padding: 4px 4px;
    min-height: 65px;
  }
  .selected-test-content.live-reports-content .single-performance-card {
    min-height: 90px;
    max-width: 49%;
    justify-content: center;
  }
  .impressions {
    padding: 12px 12px;
    column-gap: 1%;
  }
  span.test-proposition {
    font-size: 16px;
  }
  .selected-test-content-title,
  .selected-test-content-dropdown {
    align-items: flex-start;
  }
  .timeline-card-divider {
    margin-top: 12px;
  }
  .test-timeline-card-list {
    max-height: 520px;
  }
  .test-timeline-card-single:first-child:before {
    display: none;
  }
  #test-timeline .year-select {
    max-width: 49%;
    width: 100%;
    border: 1px solid #404040;
    border-radius: 3px;
    align-items: center;
  }
  .test-timeline-card-single {
    width: 100%;
    padding: 12px 12px;
  }
  .test-timeline-details {
    width: 100%;
    margin-top: 10px;
  }
  .tab-dropdown-mobile,
  .month-dropdown-mobile {
    display: block;
  }
  #test-timeline .current-year-selected {
    font-size: 16px;
  }
  #test-timeline .month-select {
    position: relative;
    max-width: 49%;
    width: 100%;
    margin-top: 0px;
  }
  #test-timeline .month-list {
    flex-wrap: wrap;
    border-bottom: 0px;
    position: absolute;
    background: #212529;
    padding: 10px 10px;
    left: 0;
    z-index: 1;
    right: 0;
    max-height: 120px;
    overflow: auto;
    border-radius: 5px;
    row-gap: 10px;
    display: none;
  }
  #test-timeline .month-list.open-month {
    display: flex;
  }
  #test-timeline .month-list .month {
    width: 100%;
  }
  #test-timeline .month-list .month-selected {
    line-height: 100%;
    font-weight: 600;
    font-size: 100%;
    text-transform: uppercase;
    color: var(--base-white-color);
    background-color: transparent;
    padding: 0px 0px;
  }
  .product-page .product-page-inner {
    position: relative;
  }
  /* .list-client-documents ul{
    column-gap: 2%;
  } */
  .list-client-documents > .links-wrapper {
    margin-top: 0px;
  }
  .links-wrapper .single-link-wrapper {
    max-width: 49%;
    padding: 8px 12px;
  }
  .single-link-wrapper .link-details > * {
    font-size: 15px;
  }
  .single-link-wrapper .link-details > p {
    font-size: 12px;
  }
  .single-link-wrapper .link-details {
    max-width: 100%;
    margin-right: 15px;
  }

  .list-client-documents ul li {
    max-width: 100%;
  }
  .header-wrapper .header-inner span:not(.name) {
    /* max-width: 39px; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tab-component {
    flex-direction: column;
    border-bottom: none;
    gap: 0px;
    display: none;
    padding: 5px 5px;
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
    margin-bottom: 0px;
    background-color: var(--base-background-card);
    /* top: 0; */
  }
  .tab-component.open-tabs,
  .tab-component.open-graph {
    display: block;
  }
  .tab-component .tab-active {
    background-color: #404040;
  }
  .tab-component .tab {
    border-radius: 5px 5px;
    justify-content: flex-start;
    padding: 5px 5px;
    position: relative;
  }
  .tab-component .tab.tab-active::after {
    content: url("data:image/svg+xml,%3Csvg width=%2715%27 height=%2712%27 viewBox=%270 0 11 8%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M3.45455 8L0 4.36364L3.45455 6.18182L10.5455 0L3.45455 8Z%27 fill=%27%23F6F6F6%27/%3E%3C/svg%3E%0A");
    right: 4px;
    position: absolute;
  }
  .tab-component .tab-active .tab-title {
    color: #fff;
  }
  .product-page-card {
    max-width: 100%;
  }
  .product-page-search .search-wrapper {
    max-width: 80%;
    margin-right: 0px;
    padding-bottom: 4px;
  }
  .product-card-view {
    max-width: 20%;
    justify-content: flex-end;
  }
  .acc-newselect__indicator {
    background-size: 9px;
    width: 12px;
  }
  .acc-newselect__menu-list .acc-newselect__option,
  :is(.acc-newselect__value-container) .acc-newselect__single-value,
  .acc-newselect__placeholder,
  .acc-newselect__input,
  .average-revenue-date-selector > span > button {
    /* font-size: 10px; */
    font-size: 12px;
    line-height: 15px;
  }
  .acc-newselect__input {
    font-size: 15px !important;
    line-height: 15px !important;
  }
  .acc-newselectwrapper .acc-newselect__menu {
    font-size: 10px;
    padding: 5px;
  }
  .acc-newselect__menu-list .acc-newselect__option {
    padding: 4px 4px;
  }
  .client-details {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .acc-newselectwrapper .acc-newselect__control {
    min-height: 22px;
    height: 100%;
  }
  .analytics-card-figures {
    flex-wrap: wrap;
    column-gap: 3px;
    row-gap: 5px;
    padding-top: 12px;
  }
  .analytics-card-figures .analytics-percentage svg {
    width: 10px;
    height: 10px;
  }
  .analytics-card-figures .analytics-percentage .figure {
    font-size: 10px;
  }
  .analytics-cards-list .analytics-card {
    padding: 8px 8px;
  }
  .client-dropdown .section-heading,
  .properties-dropdown .section-heading {
    margin-bottom: 5px;
    font-size: 22px;
  }
  .authlayout-wrapper.sidebar-is-open .account-details .notification,
  .authlayout-wrapper.sidebar-is-open .account-details .account {
    display: none;
  }
  .authlayout-wrapper.sidebar-is-open .account-details-inner .closemenu {
    display: block;
  }
  .sidebar-list {
    max-width: 100%;
  }
  .tests-canbe-evaluated span {
    font-size: 15px;
  }
  .reporting-subtitle {
    font-size: 14px;
  }
  .live-reports-content .experiment-data-inner:last-child {
    margin-top: 30px;
  }
  .experiment-heading-divider > .section-heading-data {
    width: 100%;
    margin-bottom: 10px;
  }
  .reporting-mobile-filters {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .selected-test-content.live-reports-content
    .experiment-data-inner:not(:last-child) {
    margin-top: 15px;
  }
  .experiment-heading-divider {
    margin-bottom: 15px;
  }
  .significance-heading .section-heading-data {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .significance-heading .section-heading-subtitle {
    margin-left: 0px;
  }
  .experiment-data-navbar.history-tabs
    div
    span.rp-context
    span:not(.history-date) {
    font-size: 13px;
  }
  .header-bar .header-bar-inner button.add {
    max-width: initial;
  }
  .test-hypothesis-inner .hypothesis-filter {
    gap: 5px;
  }
  .hypothesis-filter .single-filter {
    max-width: 32%;
  }
  .product-page .calculated-kpis-variants,
  .product-page .selected-test-content .graph-component {
    margin-top: 28px;
  }
  .product-card-list-view .list-view-headings,
  .product-card-list-view .product-page-card {
    min-width: 900px;
  }
  .product-card-list-view .page-card-title,
  .product-card-list-view .footer-title-data {
    font-size: 14px;
  }
}
@media (max-width: 535px) {
  .filtertests-tabs .filtertests-tabs-inner button {
    width: 100%;
    max-width: 49%;
    font-size: 15px;
    letter-spacing: 0.5px;
  }
  .data-store-tabs {
    display: flex;
    flex-direction: column;
  }
  .data-store-tabs > button {
    width: 100%;
  }
}
@media (max-width: 428px) {
  .datepicker-wrapper.dark-calendar-wrapper {
    width: 100%;
    max-width: 100%;
  }
  .rdrDateRangePickerWrapper,
  .dark-calendar .rdrCalendarWrapper,
  .rdrMonth {
    width: 100%;
  }
  .rdrMonths.rdrMonthsVertical .rdrMonth:nth-child(2) {
    margin-top: 15px;
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrDayNumber {
    top: 0px;
    bottom: 0px;
  }
  .rdrDay {
    line-height: 1em;
    height: 2em;
  }
  .datepicker-wrapper .buttons-operations {
    margin-top: 9px;
  }
  .rdrDays {
    row-gap: 8px;
  }
}
