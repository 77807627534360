.experiment-lists{
    display: flex;
    flex-direction: column;
    column-gap: 2%;
    row-gap: 20px;
}
.experiment-overview{
    padding: 20px 10px;
    display: none;
    &.active{
        display: block;
    }
    &>h2{
        color: #f6f6f6;
        padding-bottom: 15px;
        font-weight: 400;
        font-size: 23px;
        font-weight: 500;
        line-height: 110%;
        margin-bottom: 0px;
    }
    &>p{
        color: #f6f6f6;
        padding-bottom: 15px;
        font-weight: 400;
        font-size: 18px;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 0px;
    }
    .add__new__test__form-footer{
        button{
            margin-left: auto;
        }
    }
}