.onboarding{
 ul {
    li {
        h3{
            font-weight: var(--font-600);
        }
    }
 }
 &.overview{
    ul{
        li{
            flex: 1 1 0;
        }
    }
    .count{
        color: var(--desc-color-lite);
        font-weight: var(--font-600);
        font-size: var(--value24);
    }
 }
} 