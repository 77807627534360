@use './Variables';

@media (max-width: Variables.$tablet){
    :root {
        --heading-size: 30px;
        --card-padding: 15px 8px;
        --tablehead-padding: 12px 16px;
        --xl-fontsize : 16px;
        --default-fontsize: 14px;
        --large-fontsize: 14px;
        --medium-fontsize: 12px;
        --value10: 10px;
        --value24: 20px;
        --value12: 10px;
        --value16: 12px;
        --value8: 8px;
        --default-margin-bottom: 20px;
        --default-row-gap:15px;
    }
    body{
        &::-webkit-scrollbar:vertical {
            width: 1px;
            background-color: var(--bg-color3)
        }        
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 1px solid var(--color-neon);
            background-color: var(--color-neon);
        }
      }
    .section-pd-top{
        padding: 18px 0px 0px 0px;
    }
    .section-pd-bottom{
        padding: 0px 0px 18px 0px;
    }
    .section-pd{
        padding: 18px 0px;
    }
    .line-chart-wrapper{
        .chart{
            padding: 0px 0px 0px 0px;
        }
    }
    .conversion-performance{
        .p-card-flex{
            row-gap: var(--default-row-gap);
            flex-wrap: wrap;
            .p-card{
                max-width: 47%;
            }
        }
        // .sucess-rate-insights-wrapper{
        //     flex-wrap: wrap;
        // }
    }
    .t-card-flex{
        .success-rt{
            max-width: 100%;
        }
        .insights-crds{
            max-width: 100%;
            // .insight-card{
            //     max-width: 100%;
            // }
        }
    }
    .report-card{
        max-width: 49.47%;
        border-width: 1px;
    }
    .smart-button{
        border-width: 1px;
    }
    .history-card, .sequential-results{
        border-width: 1px;
    }
    .conversion-performance{
        .p-card-flex {
            .p-card{
                border-width: 1px;
            }
        }
    }
    .field-wrapper {
        .input-wrapper{
            border-width: 1px;
        }
    }
    .conversion-mainLayer {
        &.close{
            .conversion-sidebar {
                max-width: fit-content;
                min-width: fit-content;
                padding: 0px 8px;
                // ul {
                //     li {
                //         a {
                //             span {
                //                 &.route {
                //                     display: none;
                //                 }
                //             }
                //         }
                //     }
                // }
                // .logo{
                //     &:not(.mobile){
                //         display: none;
                //     }
                //     &.mobile{
                //         padding: var(--card-padding);
                //         display: flex;
                //         align-items: center;
                //         justify-content: center;
                //         svg{
                //             height: 28px;
                //             width: 28px;
                //         }
                //     }
                // }
            }
        }
    }
}   

@media (max-width: Variables.$ipad){
    .t-card-flex .success-rt {
        min-height: 135px;
    }
    // .conversion-mainLayer {
    //     .conversion-sidebar {
    //         max-width: fit-content;
    //         min-width: fit-content;
    //         padding: 0px 8px;
            // ul {
            //     li {
            //         a {
            //             span {
            //                 &.route {
            //                     display: none;
            //                 }
            //             }
            //         }
            //     }
            // }
            // .logo{
            //     &:not(.mobile){
            //         display: none;
            //     }
            //     &.mobile{
            //         padding: var(--card-padding);
            //         display: flex;
            //         align-items: center;
            //         justify-content: center;
            //         svg{
            //             height: 28px;
            //             width: 28px;
            //         }
            //     }
            // }
        // }
        // main{
        //     max-width: calc(100% - 63px);
        //     .conversion-content{
        //         padding: var(--card-padding);
        //     }
        // }
    // }
}

@media (max-width: Variables.$mobile){
    :root {
        --heading-size: 20px;
        --default-fontsize: 12px;
        --medium-fontsize: 13px;
        --large-fontsize: 14px;
        --xl-fontsize : 16px;
        --card-padding: 15px 8px;
        --tablehead-padding: 12px 16px;
        --value10: 10px;
        --value24: 20px;
        --value12: 10px;
        --value16: 12px;
        --value8: 6px;
        --default-margin-bottom: 10px;
        --default-row-gap:15px;
    }
    .conversion-performance .p-card-flex .p-card .p-card-bottom span:last-child , .t-card-flex .success-rt .card-top span {
        font-size: 24px;
    }
    .filters-list .select-component {
        border: 1px solid var(--border-color);
        padding: 4px var(--value8);
        border-radius: 2px;
        min-width: auto;
        // max-width: 49%;
        background-color: var(--bg-color3);
    }
    .report-card{
        max-width: 100%;
    }
    .conversion-performance{
        .p-card-flex{
            .p-card{
                max-width: 100%;
            }
            &.admin-performance-overview-cards{
                .p-card{
                    max-width: 100%;
                }
            }
        }
        .sucess-rate-insights-wrapper{
            flex-direction: column;
            row-gap: var(--default-row-gap);
        }
    }
    .tests-filter-wrapper, .sequential-cards-wrapper{
        flex-wrap: wrap;
        row-gap: var(--default-row-gap);
    }
    .results-filter-wrapper{
        row-gap: var(--default-row-gap);
        >div:first-child{
            flex-wrap: wrap;
            row-gap: var(--default-row-gap);
            div:last-child{
                flex-wrap: wrap;
                row-gap: 4px;
                justify-content: normal;
            }
        }
        >div:last-child{
            justify-content: space-between;
            width: 100%;
        }
    }
    .conversion-topbar {
        .store-selectors {
            column-gap: 5px;
            flex-wrap: wrap;
            row-gap: var(--default-row-gap);
        }
    }
    .select-component {
        .conversion-select__value-container {
            max-width: 110px;
            min-width: 110px;
        }
    }
    .conversion-topbar {
        .actions {
            ul {
                li{
                    .switch-theme{
                        display: none;
                    }
                }
            }
        }
    }
    .conversion-mainLayer {
        .conversion-sidebar {
            max-width: 120px;
            min-width: 120px;
            ul{
                li{
                    a{
                        padding: 12px 12px;
                    }
                }
            }
        }
        &.close{
            .conversion-sidebar{
                padding: 0px 4px;
                ul{
                    li{
                        a{
                            &.active{
                                padding: 12px 12px;
                            }
                        }
                    }
                }
            }
            main{
                max-width: calc(100% - 55px);
            }
        }
        main{
            width: 100%;
            max-width: calc(100% - 120px);
            margin-left: auto;
            .conversion-content {
                padding: 15px;
            }
        }
    }
    .sequential-results{
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: var(--default-row-gap);
        >div{
            max-width: 42%;
            width: 100%;
        }
    }
    .select-component .conversion-select__control {
        column-gap: 2px;
    }
    .business-case-validation-wrapper {
        .business-case-data {
            .metrics-card {
                max-width: 100%;
            }
        }
    }
    .field-wrapper {
        .input-wrapper {
            padding: var(--value8) var(--value16);
        }
    }
    .smart-button.big {
        padding: var(--value8) var(--value16);
    }
    .filters-list{
        flex-wrap: wrap;
    }
    .sharable-report-detail {
        .conclusion-factors-wrapper {
            flex-wrap: wrap;
            row-gap: var(--default-row-gap);
        }
    }
}